import AgilityModules from '../../agility-pageModules/AgilityModules';

const Column = ({modules, fields}) => {
    const { cSSClasses, sizeXS, sizeSM, sizeMD, sizeLG } = fields;
    let classNames = "";
    if(!!cSSClasses){
        let classes_list = cSSClasses.map(item => item.fields.value);
        classNames = classes_list.join(' ');
    }
    if(!!sizeXS){
        classNames += ` column-xs-${sizeXS}`;
    }
    if(!!sizeSM){
        classNames += ` column-sm-${sizeSM}`;
    }
    if(!!sizeMD){
        classNames += ` column-md-${sizeMD}`;
    }
    if(!!sizeLG){
        classNames += ` column-lg-${sizeLG}`;
    }

    return (
        <div className={`column ${classNames}`}>
            {modules.map((m, index) => {
                let CurrentComponent = AgilityModules.DefaultModule;
                if (AgilityModules.hasOwnProperty(m.moduleName)) {
                    CurrentComponent = AgilityModules[m.moduleName];
                }
                if(!m.item) return;
                return <CurrentComponent key={index} contentID={m.item.contentID} {...m.item.fields} {...m.item.properties}/>
            })}
      </div>
    );
};

export default Column;