import React, { useContext } from 'react';
import LabelsContext from 'src/context/LabelsContext';
import s from './LinkPanel.module.scss';
import cx from 'classnames';

import Cta from '../CallToAction/CallToAction';

const LinkPanel = (props) => {
  /*var props.buttonPosition == 'top';
  const header = props.buttonPosition == "bottom" ?
                <h2 className={s.link_panel__heading}>{props.title}</h2> :
                (<div className={s.link_panel_row}>
                  <div className={s.link_panel__header__heading_container}><h2 className={s.link_panel__heading}>{props.title}</h2></div>
                  <div className={s.link_panel__header__go_container}><div className={s.link_panel_home__go}>{props.url.text}</div></div>
                </div>);*/
  
  const { labels } = useContext(LabelsContext);
  // const labels = typeof window !== 'undefined' ?  mescaLabels : props.labels;
  return (
      <a href={props.url?.href} target={props.url?.target} className={s.root}>
        <div className={s.link_panel__image_holder}>
          <img src={props.image.url} alt={props.image.label} className={s.link_panel__image} />
        </div>
        <div className={s.link_panel__header}>
          <div className={s.link_panel_row}>
            <div className={s.link_panel__header__heading_container}><h2 className={s.link_panel__heading}>{props.title}</h2></div>
            <div className={s.link_panel__header__go_container}><div className={s.link_panel_home__go}>{labels.linkPanelDefaultText}</div></div>
          </div>
        </div>
        <p className={s.link_panel__description}>{props.description}</p>
        {/*{ props.buttonPosition == "bottom" ? <Cta text={props.url.text} /> : false }*/}
      </a>
  );
};

export default LinkPanel;