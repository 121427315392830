import * as React from "react";

function GlobeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path
          d="M15.774 21.599a14.941 14.941 0 002.02-3.167h2.407c-1.121 1.484-2.656 2.606-4.427 3.167zM6.18 18.432h2.494a14.835 14.835 0 002.043 3.196c-1.816-.548-3.394-1.68-4.537-3.196zm4.537-14.81a14.851 14.851 0 00-2.089 3.287H6.115c1.148-1.56 2.75-2.728 4.602-3.287zm9.548 3.287h-2.426a14.835 14.835 0 00-2.065-3.257c1.806.571 3.365 1.728 4.491 3.257zM19.085 12a13.553 13.553 0 00-.744-3.841h2.711A9.652 9.652 0 0122.1 12h-3.015zm-5.27 5.182V13.25h4.02a12.42 12.42 0 01-.871 3.932h-3.149zm0 1.25h2.574a13.762 13.762 0 01-2.574 3.457v-3.457zm-1.25 0v3.366a13.797 13.797 0 01-2.487-3.366h2.487zM8.632 13.25h3.933v3.932H9.503a12.42 12.42 0 01-.871-3.932zm3.933-5.091V12H8.632a12.44 12.44 0 01.835-3.841h3.098zm0-1.25h-2.534a13.767 13.767 0 012.534-3.457v3.457zM17 8.159c.479 1.23.765 2.522.835 3.841h-4.02V8.159H17zm-3.185-1.25V3.362a13.766 13.766 0 012.621 3.547h-2.621zm-8.487 1.25h2.799A13.554 13.554 0 007.382 12H4.28a9.652 9.652 0 011.048-3.841zM4.28 13.25h3.102c.065 1.347.328 2.669.78 3.932H5.38a9.66 9.66 0 01-1.1-3.932zm16.721 3.932h-2.696c.452-1.263.715-2.585.78-3.932H22.1a9.66 9.66 0 01-1.099 3.932zM13.19 2C7.556 2 3 6.763 3 12.625S7.556 23.25 13.19 23.25c5.635 0 10.19-4.763 10.19-10.625S18.825 2 13.19 2z"
          id="prefix__globe"
        />
      </defs>
      <use xlinkHref="#prefix__globe" fillRule="evenodd" />
    </svg>
  );
}

export default GlobeIcon;
