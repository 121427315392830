import React from 'react';
import ReactHtmlParser from "react-html-parser";
import s from './IconsListing.module.scss';
import cx from 'classnames';

const IconsListingItem = ({title, description, icon}) => {
  return (
    <div className={s.itemWrapper}>
      {!!icon && <img src={icon.url} alt={icon.label} className={s.itemIcon}/>}
      {!!title && <h4 className={cx(s.itemTitle, 'item-title')}>{ReactHtmlParser(title)}</h4>}
      {!!description && <p className={s.itemDescription}>{description}</p>}
    </div>
  );
};

export default IconsListingItem;