import React, { useContext } from "react";
import LabelsContext from "src/context/LabelsContext";
import s from "./Footer.module.scss";
import cx from "classnames";
import GlobeIcon from "../Icons/Globe";
import AngleRightIcon from "../Icons/AngleRight";
import Link from "next/link";
import MemoFacebook from "../Icons/Facebook";
import MemoInstagram from "../Icons/Instagram";
import MemoLinkedIn from "../Icons/LinkedIn";
import MemoYoutube from "../Icons/Youtube";

const Footer = () => {
  const { labels } = useContext(LabelsContext);
  return (
    <footer className={cx(s.root)}>
      <div className={cx(s.global_direct)}>
        <div>
          <a href={labels?.footerVisitLocalWebsiteUrl}>
            {labels?.footerVisitLocalWebsiteText} <GlobeIcon />
          </a>
        </div>
      </div>
      <div className={s.footer_content}>
        <div className={s.follow_us}>
          <h3>{labels?.footerFollowUsText}</h3>
          <div className={s.icon_list}>
            <a
              href="https://www.facebook.com/MitsubishiElectricCanada/"
              target="_blank"
            >
              <MemoFacebook />
              <span className="sr-only">Facebook</span>
            </a>
            {/* <a href="" target="_blank">
              <MemoInstagram />
              <span className="sr-only">Instagram</span>
            </a> */}
            <a
              href="https://www.linkedin.com/company/mitsubishi-electric-sales-canada-inc-/"
              target="_blank"
            >
              <MemoLinkedIn className={s.linkedin} />
              <span className="sr-only">LinkedIn</span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCjBQ6V0LO1Nvbm0Z-i-4e0A"
              target="_blank"
            >
              <MemoYoutube />
              <span className="sr-only">youtube</span>
            </a>
            <div className={s.approved_accounts}>
              <AngleRightIcon /> <h4>{labels?.footerSocialMediaApprovedText}</h4>
            </div>
          </div>
        </div>
        <div className={s.footer_links}>
          <div>
            <nav className={s.nav}>
              <ul className={s.mainMenu}>
                <li>
                  <a href={labels?.privacyLink}>{labels?.privacyPolicyText}</a>
                </li>
                
                <li className={s.divide}>
                  <a href={labels?.termsOfUseLink}>{labels?.termsOfUseText}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className={s.copyright}>
            <p>&copy; Mitsubishi Electric Sales Canada Inc.</p>
          </div>
        </div>
      </div>
      <a href="#top" className={s.back_to_top}>
        <span className="sr-only">Back to top</span>
        <AngleRightIcon />
      </a>
    </footer>
  );
};

export default Footer;
