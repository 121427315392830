import { cloneDeep, intersection } from 'lodash';

export function orderItemsByAgilityOrder (items) {
    return items.sort((a, b) => a.properties?.itemOrder - b.properties?.itemOrder);
} 

export function orderItemsBySortingArray (items, sortingArr) {
    let _items = [...items];
    _items.sort((a,b)=> {
        var A = String(a.contentID), B = String(b.contentID);
        return sortingArr.indexOf(A) > sortingArr.indexOf(B) || sortingArr.indexOf(A) == -1 || sortingArr.indexOf(B) == -1 ? 1 : -1;
    })
    return _items;
} 

export function checkIfFile (pathname) {
    return pathname?.split('/')?.pop()?.indexOf('.') > 0;
} 

// Recursive function to get sitemap item that is visible in menu
export function getSiteMapNestedShowOnMenu(list = []){
    let _list = cloneDeep(list);
    _list = _list.filter( item => {
        if(!!item.children){
            item.children = getSiteMapNestedShowOnMenu(item.children);
        }
        return !!item.visible.menu;
    })
    return _list;
}

export function searchParentPages(sitemapNested, sitemapNode) {
    var result = [];
    sitemapNested.some((item) => {
        let { children } = item;
        if (isTwoSitemapNodesEqual(sitemapNode, item)) 
            return result.push(item);

        if(!!children){
            var temp = searchParentPages(children, sitemapNode);
            if (temp.length) return result = [item, ...temp];
        }
    });
    return result;
}

// Recursive function to tranform sitemap item to have href field & resolve redirect
export function tranformSitemapNested(sitemapNested = [], sitemapNode){
    let _list = cloneDeep(sitemapNested);
    _list = _list.map( item => {
        if(!!item.children){
            item.children = tranformSitemapNested(item.children, sitemapNode);
        }
        let href = !!item.redirect ? item.redirect.url.replace('~', '') : !!item?.isFolder ? '#' : item.path;
        return {
            ...item,
            href: href,
            target: item?.redirect?.target || '_self',
            isCurrent: isTwoSitemapNodesEqual(sitemapNode, item),
            isParentOfCurrent: !!item.children && !!item.children.find(c => (!!c.isParentOfCurrent || isTwoSitemapNodesEqual(sitemapNode, c)))
        };
    })
    return _list;
}

function isTwoSitemapNodesEqual(sitemapNode1, sitemapNode2) {
    let pageIsFound = sitemapNode1?.pageID == sitemapNode2?.pageID;
    return !!sitemapNode1?.contentID ? (sitemapNode1?.contentID == sitemapNode2?.contentID && pageIsFound) : pageIsFound
}


export function findDynamicUrl(sitemap, contentID, pathname = ""){
    let keys = Object.keys(sitemap);
    let basePathOfDynamicItem = removeLastPathFromPathname(pathname);
    let dynamicPath = keys.find(key => {
        let item = sitemap[key];
        let itemBasePath = removeLastPathFromPathname(item.path);
        if(!!item.title && (item.contentID == Number(contentID)) && (basePathOfDynamicItem == itemBasePath)) {
            let url = item.redirect ? item.redirect.replace('~', '') : item.path;
            return url
        }
    })
    return dynamicPath;
}

export function removeLastPathFromPathname(pathname) {
    let split = pathname.split('/');
    split.pop();
    return(split.join('/'));
}

export function findRowColumn(array){
    let result = [], group = [];
    let gridColBeginIndex = array.findIndex( m => m.moduleName == 'GridColumnBegin');
    let gridRowEndIndex = array.findIndex( m => m.moduleName == 'GridRowEnd');
    if(gridColBeginIndex != -1){
        result = result.concat(array.slice(0, gridColBeginIndex));
        if(gridRowEndIndex != -1){
            group = array.slice(gridColBeginIndex, gridRowEndIndex);
            result.push({
                moduleName: 'Row',
                columns: convertGroupToColumns(group)
            })
            if(gridRowEndIndex < array.length){
                result = result.concat(findRowColumn(array.slice(gridRowEndIndex + 1)))
            }
        }else{
            group = array.slice(gridColBeginIndex);
            result.push({
                moduleName: 'Row',
                columns: convertGroupToColumns(group)
            })
        }
    }else{
        result = array;
    }
    return result;
}

function convertGroupToColumns (array) {
    let columns = [];
    // find all indexes of GridColumnBegin
    let gridColumnIndexes = array.reduce((prev, cur, idx) => {
        if(cur.moduleName == 'GridColumnBegin') prev.push(idx);
        return prev;
    }, [])

    // group data into column -> array of object
    gridColumnIndexes.forEach((gridColumnIdx, idx) => {
        let nextIndex = idx + 1;
        let modules = array.slice(gridColumnIdx + 1, gridColumnIndexes[nextIndex]);
        if(!!modules && !!modules.length){
            columns.push({
                moduleName: 'Column',
                fields: array[gridColumnIdx].item.fields,
                modules: modules
            })
        }
    });
    // console.log({columns})
    return columns;
}


/**
 * Convert javascript date to EST date ("yyyy-mm-dd") or time ("hh:mm:ss")
 * @param {Date} date
 * @param {String} type
 * @returns ESTString
 */
export function getEasternDateOrTime(date, type = 'date') {
    let options = {}
    switch (type) {
        case 'time':
            options = {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }
            break;
        case 'date':
            options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }
            break;
    }
    options['timeZone'] = 'Canada/Eastern';
    const string = date.toLocaleString('en-CA', options);
    return string;
}


export function transformToLocaleHref(href = '#', locale = 'en') {
    let _href = href?.replace('~','');
    return (/^(\/en$|\/fr$).*/.test(_href) || /^\/en|fr\//.test(_href) || !_href?.startsWith('/')) ? _href : `/${locale}${_href}`;
}

