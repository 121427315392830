import React from 'react';
import RichTextArea from '../RichTextArea/RichTextArea';
import s from './VerticalTabs.module.scss';
import cx from 'classnames';

import ContentSwitcher from '../ContentSwitcher/ContentSwitcher';

const VerticalTabs = (props) => {
  let panes = props.tabs.map((el, index) => {
    return (
      <ContentSwitcher.Pane key={index} panelTitle={el.title} downloadLink={el.downloadLink} label={el.title}>
        <div><RichTextArea textblob={el.content} locale={props.locale}/></div>
      </ContentSwitcher.Pane>
    )
  });
  return (
    <div className={s.root}>
      <ContentSwitcher.Tabs navWrapperClass="column column-xs-12 column-sm-3" contentWrapperClass="column column-xs-12 column-sm-9" breakpoint={768} contentClassName={s.content} activeLinkClass={s.active} contentTitleClass={s.contentTitleClass} modalCloseClass={s.modalClose} closeLinkClass={s.close} className="row" selected="0">
        {panes}
      </ContentSwitcher.Tabs>
    </div>
  );
};

export default VerticalTabs;