import React from 'react';
import LabelsContext from 'src/context/LabelsContext';
import s from './WhereToBuyForm.module.scss';
import cx from 'classnames';

import CallOutPanel from '../CallOutPanel/CallOutPanel';

import dropdown from '../WhereToBuy/dropdown.png';

class WhereToBuyForm extends React.Component {

  static contextType = LabelsContext;

  constructor(props) {
    super(props);
    this.state = {
      productGroup: 'M',
      commercial: false,
      postalCode: "",
    };
  };

  _handleSelectionType = (event) => {
    const commercial = event.target.value === 'commercial' ? true : event.target.value === 'residential' ? false : null;
    const productGroup = event.target.value === 'residential' ? 'M' : null
    this.setState({
      commercial: commercial,
      productGroup: productGroup
    });
  }

  _handleDuctType = (event) => {
    const dists = mapDistrubutors(this.state.postalCode);
    this.setState({ productGroup: event.target.value, distributors: dists });
  }
  render = () => {
    const _style = { backgroundImage: `url(${dropdown.src})` }
    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;
    return (
      <CallOutPanel className={s.root}>
        <div className={s.whereToBuy}>
          <h3 className={s.headingf}>{labels.whereToBuy_Heading}</h3>
          <div className={s.link_panel__dropdowns}>
            <form action="/hvac/where-to-buy">
              <input type="text" className="input" name="postal_code" placeholder={labels.postalCodePlaceholder} />
              <select onChange={this._handleSelectionType} name="commercial" id="commercial" className={s.select} style={_style}>
                <option value="residential">{labels.whereToBuy_Residential}</option>
                <option value="commercial">{labels.whereToBuy_Commercial}</option>
              </select>
              {/* {!this.state.commercial && <select onChange={this._handleDuctType} name="productGroup" id="productGroup" className={s.select} style={_style}>
                  <option value="M">{labels.whereToBuy_Ductless}</option>
                  <option value="Z">{labels.whereToBuy_Ducted}</option>
              </select>} */}
              <input className="input_submit" type="submit" name="" value={labels.whereToBuy_SubmitButtonText} placeholder="" />
            </form>
          </div>
        </div>
      </CallOutPanel>
    );
  }
};

export default WhereToBuyForm;