import { forwardRef, useEffect, useRef, useState } from "react";
import LanguageSwitcher from "../LanguageSwitcher";
import LoadingWidget from "../LoadingWidget";
import GlobeIcon from "../Icons/Globe";
import SearchIcon from "../Icons/Search";
import AngleRightIcon from "../Icons/AngleRight";
import { useRouter } from "next/router";
import cx from "classnames";
import s from "./HeaderMobile.module.scss";
import MemoMenuBarsIcon from "../Icons/MenuBars";
import MemoCloseIcon from "../Icons/CloseIcon";
import Link from "next/link";

const HeaderMobile = ({ location, logo, links, languageCode }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState({});
  const router = useRouter();

  // const handleLinkClick = (href, target) => {
  //   if (target === "_blank") {
  //     window.open(href);
  //   } else {
  //     setIsLoading(true);
  //     router.push(href).then(() => {
  //       setShowMenu(false);
  //       setActiveMenuItem({});
  //       setIsLoading(false);
  //     });
  //   }
  // };

  //closes dropdown when links are clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setActiveMenuItem({});
      setShowMenu(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <div className={cx(s.root, s.mobile)}>
      <div className={s.navbar}>
        <div className={s.nav_left}>
          <span className={s.logo_link}>
            <Link href="/" aria-label="home">
              <img height="72" src={logo.url} alt="logo" />
            </Link>
          </span>
          <span className={s.site_title}>{location}</span>
        </div>
        <div className={s.nav_right}>
          <LanguageSwitcher locale={languageCode} className={s.lang_switcher} />

          <a
            href="https://www.mitsubishielectric.com/en/index.html"
            target="_blank"
            className={s.global_site_link}
            aria-label="Visit Mitsubishi Electric Global"
          >
            <GlobeIcon style={{ fontSize: "28px" }} />
            <span className="sr-only">Visit Mitsubishi Electric Global</span>
          </a>

          <button
            onClick={() => setShowMenu(!showMenu)}
            className={s.menu_icon}
            aria-label={showMenu ? "Close Menu" : "Open Menu"}
          >
            {showMenu ? (
              <MemoCloseIcon width={20} height={20} />
            ) : (
              <MemoMenuBarsIcon width={20} height={12} />
            )}
                 <span className="sr-only">{showMenu ? "Close Menu" : "Open Menu"}</span>
          </button>
        </div>
      </div>

      <div
        className={cx(s.menu_container)}
        style={{
          transform: showMenu ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <div
          className={s.search_box}
          style={{ display: showMenu ? "block" : "none" }}
        >
          <form action="/search" method="get">
            <label className={s.sr_only} htmlFor={"search"}>
              Search
            </label>
            <input
              type="text"
              name="q"
              id="search"
              placeholder="Search Mitsubishi Electric"
              className={s.search_input}
            ></input>
            <button
              className={s.search_submit}
              type="submit"
              aria-label={"Search"}
              name="search_submit_button"
              role="button"
            >
              <SearchIcon style={{ fontSize: "28px" }} />
              <span className="sr-only">Submit Search</span>
            </button>
          </form>
        </div>
        <ul style={{ display: showMenu ? 'block' : 'none' }}>
          {links.map((item) => (
            <MenuItem
              key={item.pageID}
              item={item}
              languageCode={languageCode}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuItem = ({ item, languageCode }) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const href =
    item.name === "home" || item.name === "Accueil" ? "/" : item.href;

  //closes dropdown when links are clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <li className={s.submenu_list_item}>
      {item?.children && item.children.length > 0 ? (
        <button onClick={() => setOpen(!open)}>
          {item.menuText} <AngleRightIcon />
        </button>
      ) : item?.target === "_blank" || item.redirect ? (
        <a href={item.href} target={item.target}>
          {item.menuText}
        </a>
      ) : (
        <Link href={href}>{item.menuText}</Link>
      )}

      <div
        className={s.sub_menu_container}
        style={{
          left: open ? "0" : "100%",
        }}
      >
        {" "}
        {open && (
          <>
            <div className={s.sub_menu_title}>
              <h3>{item?.menuText}</h3>
              <button className={s.back} onClick={() => setOpen(false)}>
                <AngleRightIcon
                  style={{
                    transform: "rotate(180deg)",
                    fill: "white",
                  }}
                />
                {languageCode === "fr" ? "Retour" : "Back"}
              </button>
            </div>
            <ul className={s.sub_menu_list}>
              {item.href !== "#" && (
                <li className={s.submenu_list_item} key={item.pageID}>
                  <Link href={href}>{item.menuText}</Link>
                </li>
              )}

              {item.children.map((child) => (
                <MenuItem key={child.pageID} item={child} />
              ))}
            </ul>
          </>
        )}
      </div>
    </li>
  );
};

export default HeaderMobile;
