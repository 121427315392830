import cx from "classnames";
import s from "./HotspotImage.module.scss";
import { useState } from "react";

const HotspotImage = ({ image, hotspots }) => {
  const [activeHotspot, setActiveHotspot] = useState(0);

  return (
    <div className={s.root}>
      {!!image && <img src={image.url} alt={image.alt || image.label} className="" />}
      <ul>
        {hotspots &&
          hotspots.map((hotspot, i) => (
            <li key={i} tabIndex={activeHotspot === i ? 0 : -1}>
              <button
                onClick={() => setActiveHotspot(i)}
                className={cx(
                  s.hotspot,
                  activeHotspot === i ? s.active : s.inactive
                )}
                style={{
                  top: `${hotspot?.fields?.offsetY}%`,
                  left: `${hotspot?.fields?.offsetX}%`,
                }}
                aria-label={hotspot.title}
              >
                <span>{`Select ${hotspot?.fields?.title}`}</span>
              </button>

              {activeHotspot === i ? (
                <div className={s.title}>{hotspot?.fields?.title}</div>
              ) : null}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default HotspotImage;
