import React, { createContext, useContext} from "react";

const LabelsContext = createContext(null);

const LabelsProvider = ({children, labels = []}) => {
    let labelsObj = {};
    labels.forEach(l => {
        let refName = l.fields.referenceName;
        let key = refName.charAt(0).toLowerCase() + refName.slice(1);
        labelsObj[key] = l.fields.referenceValue;
    })

    return (
        <LabelsContext.Provider
            value={{
                labels: labelsObj,
            }}
        >
            {children}
        </LabelsContext.Provider>
    );
}

export default LabelsContext;

export { LabelsProvider };