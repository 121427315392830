import React from 'react';

import s from './CallOutPanel.module.scss';
import cx from 'classnames';

const CallOutPanel = ({content, children, className}) => {
  return (
      <div className={cx(s.root, 'row', className)}>{content || children}</div>
  );
};

export default CallOutPanel;