import s from './RichTextWidthBackground.module.scss'
import RichTextArea from '../RichTextArea/RichTextArea'

const RichTextWidthBackground = (props) => {
    const { html, enableDarkBackground = "true", noBorderBottom = 'false', noBorderTop = 'false', textColor } = props
    let color = s.textWhite
    if(textColor == 'black') {
        color = s.textBlack
    } else if (textColor == 'red') {
        color = s.textRed
    }
    
    return <div className={`${s.root} ${color} ${enableDarkBackground == 'true' ? s.enableDarkBg : ""} ${noBorderBottom == 'true' ? s.noBorderBottom : ""} ${noBorderTop == 'true' ? s.noBorderTop : ""}`}>
        <RichTextArea textblob={html}/>
    </div>
}

export default RichTextWidthBackground