import DefaultModule from "./DefaultModule";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import CallOutPanel from "./CallOutPanel/CallOutPanel";
import CategoryLinkPanels from "./CategoryLinkPanels/CategoryLinkPanels";
import HeroSlider from "./HeroSlider/HeroSlider";
import HeroWithCTAs from "./HeroWithCTAs/HeroWithCTAs";
import HomeOwner_Sidebar from "./HomeOwner_Sidebar/HomeOwner_Sidebar";
import HomeOwners_Divider from "./HomeOwners_Divider/HomeOwners_Divider";
import HomeOwners_FindDealer from "./HomeOwners_FindDealer/HomeOwners_FindDealer";
import HomeOwners_HighlightItem from "./HomeOwners_HighlightItem/HomeOwners_HighlightItem";
import HomeOwners_Indoor from "./HomeOwners_Indoor/HomeOwners_Indoor";
import HomeOwners_Text from "./HomeOwners_Text/HomeOwners_Text";
import HorizontalTabs from "./HorizontalTabs/HorizontalTabs";
import HotspotImage from "./HotspotImage/HotspotImage";
import IconsListing from "./IconsListing/IconsListing";
import IconListWithSlider from "./IconListWithSlider/IconListWithSlider";
import JetTowelCalculator from "./JetTowelCalculator/JetTowelCalculator";
import LearnMorePanel from "./LearnMorePanel/LearnMorePanel";
import LinkPanel from "./LinkPanel/LinkPanel";
import ProductOverview from "./ProductOverview/ProductOverview";
import RichTextArea from "./RichTextArea/RichTextArea";
import SearchResults from "./SearchResults/SearchResults";
import SectionTitle from "./SectionTitle/SectionTitle";
import TwoColumnsParallelogramTab from "./TwoColumnsParallelogramTab/TwoColumnsParallelogramTab";
import TwoGreyColumns from "./TwoGreyColumns/TwoGreyColumns";
import VerticalTabs from "./VerticalTabs/VerticalTabs";
import Video from "./Video/Video";
import WhereToBuy from "./WhereToBuy/WhereToBuy";
import WhereToBuyForm from "./WhereToBuyForm/WhereToBuyForm";
import Image from "./Image/Image";
import Divider from "./Divider/Divider";
import IconGrid from "./IconGrid/IconGrid";
import RichTextWidthBackground from "./RichTextWidthBackground/RichTextWidthBackground";

const AgilityModules = {
  DefaultModule,
  Breadcrumb,
  CallOutPanel,
  CategoryLinkPanels,
  HeroSlider,
  HeroWithCTAs,
  HomeOwner_Sidebar,
  HomeOwners_Divider,
  HomeOwners_FindDealer,
  HomeOwners_HighlightItem,
  HomeOwners_Indoor,
  HomeOwners_Text,
  HorizontalTabs,
  HotspotImage,
  IconsListing,
  IconListWithSlider,
  JetTowelCalculator,
  LearnMorePanel,
  LinkPanel,
  ProductOverview,
  RichTextArea,
  SearchResults,
  SectionTitle,
  TwoColumnsParallelogramTab,
  TwoGreyColumns,
  VerticalTabs,
  Video,
  WhereToBuy,
  WhereToBuyForm,
  Image,
  Divider,
  IconGrid,
  RichTextWidthBackground,
};

export default AgilityModules;
