import React from "react";
import s from "./HomeOwners_Indoor.module.scss";
import cx from "classnames";

import st from "../SectionTitle/SectionTitle.module.scss";

const HomeOwners_Indoor = (props) => {
  const { inlineHtml } = props;

  if (!inlineHtml) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: inlineHtml }}></div>;
};

export default HomeOwners_Indoor;

// Old - English Version
// <div id="indoor_en">
//   <div id="">
//     <h2 class={cx(st.root, "rx16")}>
//       INDOOR UNITS - THE BEST OF FORM AND FUNCTION
//     </h2>
//   </div>

//   <div class="row ">
//     <div class="column column-xs-6">
//       <p>
//         Selecting your system starts with design flexibility at your disposal. Our
//         ducted and ductless solutions feature a variety of sleek and stylish indoor
//         units &ndash; the possibilities are endless. With different system
//         configurations to choose from, finding a unit that fits your home comfort
//         and d&eacute;cor needs has never been easier.
//       </p>
//     </div>

//     <div class="column column-xs-6">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/lts05386_rgb.jpg?w=640&c=2&q=60"
//           width="100%"
//           caption="false"
//         />
//       </p>
//     </div>
//   </div>

//   <div class=" indoor_section_ductless">
//     <h2 class={cx(st.root, "rx16")}>DUCTLESS INDOOR SOLUTIONS</h2>
//   </div>

//   <div class="row indoor_section_ductless">
//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-1-1-way-ceiling-mlz_m-series_indoor_residential_oneway_cassette.jpg"
//           alt=""
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>1-Way Ceiling-Cassette</h4>

//       <p>
//         Bring comfort and sophistication into your home like never before with
//         Mitsubishi Electric&rsquo;s MLZ-Series 1-Way Ceiling-Cassette. Its low
//         profile allows it to fit seamlessly into any room enhancing its environment
//         while providing comfort.
//       </p>
//       <p></p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-2-4-way-ceiling-m-series_indoor_residential_cassette_slz.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>4-Way Ceiling-Cassette</h4>
//       <p>
//         A discreet design with four air outlets and multiple airflow patterns, the
//         SLZ offers greater air coverage and distribution while mounting flush to the
//         ceiling. The SLZ comes standard with the 3D-iSee Sensor technology to
//         distribute hot or cold air as required for optimal room comfort.
//       </p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-4-wall-mount-msz-fh09-12-15na_sideshot_vents_open.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Wall-Mounted</h4>
//       <p>
//         The MSZ wall mounted units feature compact dimensions that make them the
//         ideal choice for offices, stores &amp; residential use. Use multiple units
//         in your space to combat those pesky hot or cold spots. They&rsquo;re also
//         among the quietest wall units in the industry, silently delivering a high
//         level of comfort and energy efficiency all year round.
//       </p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-5-floor-mount-mfz-kj09.12.15.18.jpg"
//           alt=""
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Floor-Mounted</h4>
//       <p>
//         The MFZ floor standing units deliver stylish yet efficient heating and
//         cooling. The sophisticated design features clean, sharp lines. It can be
//         installed with a base or recessed into a wall for dramatically reduced depth
//         (8 -15/32mm). Compact and unobtrusive, it&rsquo;s the perfect heating and
//         cooling solution for new and renovated spaces.
//       </p>
//     </div>
//   </div>

//   <div class=" indoor_section_ducted">
//     <h2 class={cx(st.root, "rx16")}>DUCTED INDOOR SOLUTIONS</h2>
//   </div>

//   <div class="row indoor_section_ducted">
//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-3-ceiling-concealed-sez_unit.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Ceiling-Concealed</h4>
//       <p>
//         A discrete model to provide home comfort, the SEZ unit can be built right
//         into the ceiling with short-run ducts and the PEAD units can be built into
//         new or existing ductwork to deliver comfort throughout the room.
//       </p>
//     </div>

//     <div id="ck_indoor_multi_ax" class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-6-multi-position-air-handler-m-series_indoor_residential_airhandler_svz_herofront-copy.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div id="ck_indoor_multi_bx" class="column column-middle column-xs-9">
//       <h4 class={s.title}>Multi-Position Air Handling Unit</h4>
//       <p>
//         The PVA and SVZ air handler is ideal for efficient heating and cooling in
//         new and or pre-existing ducted applications such as bedrooms and smaller
//         homes. The compact design blends seamlessly into tight spaces like an
//         existing furnace room or closet, so you can achieve the comfort you need
//         without compromising space.
//       </p>
//     </div>
//   </div>
// </div>

// Old - French Version
// <div id="indoor_fr">
//   <div id="">
//     <h2 class={cx(st.root, "rx16")}>
//       UNIT&#xC9;S INT&#xC9;RIEURES - LE STYLE ET LA FONCTIONNALIT&#xC9; &#xC0; LEUR
//       MEILLEUR
//     </h2>
//   </div>

//   <div class="row ">
//     <div class="column column-xs-6">
//       <p>
//         Le choix de votre syst&#xE8;me commence par une flexibilit&#xE9; de
//         conception que nous mettons &#xE0; votre disposition. Nos solutions avec et
//         sans conduit offrent une vari&#xE9;t&#xE9; d&#x27;unit&#xE9;s
//         int&#xE9;rieures aux lignes &#xE9;pur&#xE9;es et &#xE9;l&#xE9;gantes - les
//         possibilit&#xE9;s sont infinies. Trouver une unit&#xE9; qui r&#xE9;pond
//         &#xE0; vos besoins de confort et de d&#xE9;cor n&#x27;aura jamais
//         &#xE9;t&#xE9; aussi facile, gr&#xE2;ce au choix de diff&#xE9;rentes
//         configurations de syst&#xE8;me disponibles.
//       </p>
//     </div>

//     <div class="column column-xs-6">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/lts05386_rgb.jpg?w=640&c=2&q=60"
//           width="100%"
//           caption="false"
//         />
//       </p>
//     </div>
//   </div>

//   <div class=" indoor_section_ductless">
//     <h2 class={cx(st.root, "rx16")}>
//       Solutions int&#xE9;rieures sans conduits
//     </h2>
//   </div>

//   <div class="row indoor_section_ductless">
//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-1-1-way-ceiling-mlz_m-series_indoor_residential_oneway_cassette.jpg"
//           alt=""
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; de plafond cassette &#xE0; 1 voie</h4>
//       <p>
//         Offrez-vous un confort et une sophistication in&#xE9;gal&#xE9;s, avec
//         l&#x2019;unit&#xE9; de plafond cassette &#xE0; 1 voie MLZ de Mitsubishi
//         Electric. Gr&#xE2;ce &#xE0; son profil mince, elle s&#x27;int&#xE8;gre
//         partout &#xE0; la perfection, vous procurant du confort tout en
//         am&#xE9;liorant votre d&#xE9;cor.
//       </p>
//       <p></p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-2-4-way-ceiling-m-series_indoor_residential_cassette_slz.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; de plafond cassette &#xE0; 4 voies</h4>
//       <p>
//         Avec son design discret &#xE0; quatre sorties d&#x27;air et de multiples
//         configurations de d&#xE9;bit d&#x27;air, la SLZ offre un d&#xE9;bit
//         d&#x2019;air sup&#xE9;rieur et une distribution d&#x2019;air
//         am&#xE9;lior&#xE9;e, en toute discr&#xE9;tion puisqu&#x2019;elle
//         s&#x2019;encastre au ras du plafond. La SLZ est livr&#xE9;e avec la
//         technologie iSee Sensor 3D, pour diriger l&#x27;air chaud ou froid l&#xE0;
//         o&#xF9; il est n&#xE9;cessaire, pour un confort optimal.
//       </p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-4-wall-mount-msz-fh09-12-15na_sideshot_vents_open.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; murale</h4>
//       <p>
//         Les dimensions compactes de nos unit&#xE9;s murales MSZ en font le choix
//         id&#xE9;al pour les bureaux, les magasins et les r&#xE9;sidences. Disposez
//         plusieurs unit&#xE9;s dans votre espace pour &#xE9;liminer les soucis de
//         zones chaudes ou froides. Les unit&#xE9;s murales MSZ se classent
//         &#xE9;galement parmi les plus silencieuses de l&#x27;industrie et procurent
//         un niveau &#xE9;lev&#xE9; de confort et d&#x27;efficacit&#xE9;
//         &#xE9;nerg&#xE9;tique tout au long de l&#x27;ann&#xE9;e.
//       </p>
//     </div>

//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-5-floor-mount-mfz-kj09.12.15.18.jpg"
//           alt=""
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; console</h4>
//       <p>
//         Les unit&#xE9;s MFZ de style console constituent une solution de chauffage
//         et de climatisation aussi efficace qu&#x2019;&#xE9;l&#xE9;gante. Leur design
//         sophistiqu&#xE9; offre des lignes sobres et &#xE9;pur&#xE9;es. Elles peuvent
//         &#xEA;tre install&#xE9;es avec une base ou encastr&#xE9;es dans un mur, ce
//         qui diminue consid&#xE9;rablement leur profondeur (8 -15/32mm). Compactes et
//         discr&#xE8;tes, elles sont une solution de chauffage et de climatisation
//         id&#xE9;ale pour les espaces neufs ou r&#xE9;nov&#xE9;s.
//       </p>
//     </div>
//   </div>

//   <div class=" indoor_section_ducted">
//     <h2 class={cx(st.root, "rx16")}>Solutions int&#xE9;rieures avec conduit</h2>
//   </div>

//   <div class="row indoor_section_ducted">
//     <div class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-3-ceiling-concealed-sez_unit.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; de plafond encastrable</h4>
//       <p>
//         C&#x2019;est une fa&#xE7;on discr&#xE8;te de procurer du confort &#xE0; la
//         maison. L&#x27;unit&#xE9; SEZ s&#x2019;installe directement dans le plafond
//         avec des conduits courts, et les unit&#xE9;s PEAD s&#x2019;installent dans
//         des conduits neufs ou existants.
//       </p>
//     </div>

//     <div id="ck_indoor_multi_ax" class="column column-xs-3">
//       <p>
//         <img
//           src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/8-action-multizone-3-indoor-units-section-6-multi-position-air-handler-m-series_indoor_residential_airhandler_svz_herofront-copy.jpg"
//           caption="false"
//         />
//       </p>
//     </div>

//     <div id="ck_indoor_multi_bx" class="column column-middle column-xs-9">
//       <h4 class={s.title}>Unit&#xE9; de traitement d&#x27;air multipostion</h4>
//       <p>
//         L&#x2019;unit&#xE9; de traitement d&#x27;air PVA et SVZ est id&#xE9;ale pour
//         chauffer et climatiser les chambres &#xE0; coucher ou les maisons de faible
//         superficie, en utilisant des conduits neufs ou existants. Son design compact
//         lui permet de se loger facilement dans les espaces restreints disponibles,
//         comme une salle de fournaise ou un placard, pour vous offrir le confort dont
//         vous avez besoin sans empi&#xE9;ter sur votre espace.
//       </p>
//     </div>
//   </div>
// </div>
