import React from "react";

const Download = (props) => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.59985 1.0017V12.2017L1.69985 7.3017C1.51735 7.11022 1.26437 7.00185 0.99985 7.00185C0.735326 7.00185 0.48235 7.11022 0.29985 7.3017H0.29985C0.108364 7.4842 0 7.73718 0 8.00171C0 8.26623 0.108364 8.51921 0.29985 8.7017L6.79985 15.2017C7.25791 15.6024 7.94179 15.6024 8.39985 15.2017L14.8999 8.7017C15.0913 8.51921 15.1997 8.26623 15.1997 8.00171C15.1997 7.73718 15.0913 7.4842 14.8999 7.3017V7.3017C14.7173 7.11022 14.4644 7.00186 14.1999 7.00186C13.9353 7.00186 13.6823 7.11022 13.4998 7.3017L8.59985 12.2017V1.0017C8.61607 0.731842 8.51594 0.467953 8.32477 0.276786C8.1336 0.0856194 7.86971 -0.0145173 7.59985 0.00170469V0.00170469C7.06332 0.0379968 6.63614 0.46517 6.59985 1.0017H6.59985Z" fill="white"/>
            <path d="M1 16.0017H15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Download;