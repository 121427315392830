
import React from "react";
import s from "../common/HeaderNew/Header.module.scss";

const LanguageSwitcher = ({ locale, className }) => {
    const inActiveProp = {className: s.inActive};
    const EnTag = locale == 'en' ? 'p' : 'a';
    const FrTag = locale == 'fr' ? 'p' : 'a';
    const enAttr = locale == 'en' ? inActiveProp : { href: '/en' };
    const frAttr = locale == 'fr' ? inActiveProp : { href: '/fr' };
    return (
        <div className={className}>
            <EnTag className="test" {...enAttr}>EN</EnTag>
            <span className={s.separator} style={{ opacity: '.35'}}>|</span>
            <FrTag {...frAttr}>FR</FrTag>
        </div>
    );
};

export default LanguageSwitcher;
