import React from 'react';
import s from './HomeOwners_FindDealer.module.scss';
import cx from 'classnames';

const HomeOwners_FindDealer = () => {
    return (
        <div className="row">
            <div className="column column-middle column-xs-1">
                <p className={s.textCenter}>
                    <a className={cx(s.displayBlock, "button")} href="#" target="_self" rel="noopener">&nbsp;</a>
                </p>
            </div>

            <div className="column column-middle column-xs-2">
                <p><img src="https://cdn.agilitycms.com/mesca/aug2020/photo-med/10-action-whole-home-2-find-a-dealer-gettyimages-668599276.jpg" caption="false"/></p>
            </div>

            <div className="column column-middle column-xs-9">
                <p className={s.textCenter}>
                    <a className={cx(s.displayBlock, "button dealerbutton")} href="#" target="_self" rel="noopener">FIND A DEALER NEAR YOU</a>
                </p>
            </div>
        </div>
    );
};

export default HomeOwners_FindDealer;
