import React from 'react';
import s from './HomeOwners_Divider.module.scss';

const HomeOwners_Divider = () => {
  return (
    <div className="row">
      <div className="column column-xs-12">
          <hr/>
          <p className={s.textCenter}></p>
      </div>
    </div>
  );
};

export default HomeOwners_Divider;