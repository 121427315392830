import React from 'react';
import LabelsContext from 'src/context/LabelsContext';
import Slick from 'react-slick';
import s from './ProductOverview.module.scss';
import cx from 'classnames';

import HorizontalTabs from '../HorizontalTabs/HorizontalTabs';
import VerticalTabs from '../VerticalTabs/VerticalTabs';
import CallOutPanel from '../CallOutPanel/CallOutPanel';
import Testimonial from '../Testimonial/Testimonial';
import CallToAction from '../CallToAction/CallToAction';

class ProductOverview extends React.Component {

  static contextType = LabelsContext;

  constructor(props) {
    super(props);
    this.state = {
      postalInvalid: null
    };
  };

  checkPostal = (value) => {
    const postalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    this.setState({ postalInvalid: !postalCode.test(value) })
  }

  render = () => {
    let { product } = this.props;
    const productImageSettings = {
      autoplay: product.images.media.length > 1,
      autoplaySpeed: 5000,
      speed: 500,
      arrows: false,
      dots: product.images.media.length > 1,
      slidesToShow: 1,
      customPaging: function (i) {
        return <a><img width="53" height="53" src={product.images.media[i].url} /></a>
      },
    }

    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;

    const whereToBuy = (
      <div className={s.whereToBuyContainer}>
        <div className={s.whereToBuy}>
          <h3>{labels.whereToBuyProductPageLabel || 'WhereToBuy'}</h3>
          <div className={s.link_panel__dropdowns}>
            <form action="/hvac/where-to-buy">
              <div className={cx('row', s.whereToBuyForm)}>
                <input onChange={e => this.checkPostal(e.target.value)} type="text" className={cx('input', s.whereToBuyInput)} name="postal_code" placeholder={labels.postalCodePlaceholder} />
                <input type="hidden" name="productGroup" value={this.props.product.wheretoBuyProductType || "M"} />
                <input type="hidden" name="commercial" value={this.props.product.wheretoBuyProductType === "POSTAL" ? "commercial" : "residential"} />
                <input disabled={this.state.postalInvalid} className={cx('input_submit', s.whereToBuyInputSubmit)} type="submit" name="" value={labels.whereToBuyFormSubmitLabel} placeholder="" />
              </div>
              {this.state.postalInvalid && labels.invalidPostalCodeMessage}
            </form>
          </div>
        </div>
      </div>
    );

    // Method to not show content if it does not exist
    const _checkProperty = (_property) => { return _property == '.' || _property == '' || _property == '_hide' }

    // HACKY way to inject the extra items into the tab content
    const helpLinks = product.helpLinks.map(function (link, elem) {
      return link.linkString;
    }).join('');

    const tabs = [product.tab1, product.tab2];

    const _calloutgrid_size = 12 / (!_checkProperty(product.testimonial.testimonial.quote) + !_checkProperty(product.didYouKnow.content) + product.showDealerLocator);

    const testimonial = _checkProperty(product.testimonial.testimonial.quote) ? false : (
      <div className={`column column-xs-12 column-sm-${_calloutgrid_size}`}>
        <h3>{product.testimonial.title}</h3>
        <Testimonial quote={product.testimonial.testimonial.quote} cite={product.testimonial.testimonial.cite} />
      </div>
    );

    const didYouKnow = _checkProperty(product.didYouKnow.content) ? false : (<div className={`column column-xs-12 column-sm-${_calloutgrid_size}`}>
      <h3>{product.didYouKnow.title}</h3>
      {product.didYouKnow.content}
    </div>);

    const callOutPanel = didYouKnow || testimonial ? <CallOutPanel> {didYouKnow} {testimonial} </CallOutPanel> : false;

    return (
      <div className={s.root}>
        <h1 className={s.title}>{product.title}</h1>
        <div className="row">
          <div ref="imagecontainer" className={"equalize column column-xs-12 column-sm-6"}>
            <Slick {...productImageSettings}>
              {
                product.images.media.map((image, index) => {
                  return (
                    <div key={image.id}>
                      <div className={s.slider__item}>
                        <img src={image.url} alt={product.title} />
                      </div>
                    </div>
                  )
                })
              }
            </Slick>
            {product.showWhereToBuy == 'true' && whereToBuy}
          </div>
          <div ref="detailscontainer" className={cx(s.productDetails, 'equalize column column-xs-12 column-sm-6')}>
            <HorizontalTabs tabs={tabs} />
            <div className="row">
              {product.productInformationLink && <div className="column column-xs-12 column-sm-6"><CallToAction className={s.infoLink} {...product.productInformationLink} /></div>}
              {product.technicalDocumentationLink && <div className="column column-xs-12 column-sm-6"><CallToAction className={s.infoLink} {...product.technicalDocumentationLink} /></div>}
            </div>
          </div>
        </div>
        <div className="row">
          {product.details.length > 0 && <VerticalTabs tabs={product.details} />}
        </div>

        {product.warranty && product.warranty !== '<p>&nbsp;&nbsp;</p>' && product.warranty.length > 20 && (
          <div className={s.warrantyInformation}>
            <br />
            <br />
            <h3>{labels.productOverviewWarrantyLabel}</h3><div className={s.warranty} dangerouslySetInnerHTML={{ __html: product.warranty }} ></div>
          </div>
        )}

        {callOutPanel}
      </div>
    );
  }
}

export default ProductOverview;