import React from 'react';
import RichTextArea from '../RichTextArea/RichTextArea';
import s from './HorizontalTabs.module.scss';
import cx from 'classnames';

import ContentSwitcher from '../ContentSwitcher/ContentSwitcher';

const HorizontalTabs = (props) => {
  let panes = props.tabs.map((el, index) => {
    return (
      <ContentSwitcher.Pane key={el.id || index} panelTitle={el.title} label={el.label}>
        <div><RichTextArea textblob={el.content} locale={props.locale}/></div>
      </ContentSwitcher.Pane>
    )
  });
  return (
    <div className={s.root}>
      <ContentSwitcher.Tabs navWrapperClass="column column-xs-12" contentWrapperClass="column column-xs-12" contentClassName={s.content} activeLinkClass={s.active} contentTitleClass={s.contentTitleClass} modalCloseClass={s.modalClose} closeLinkClass={s.close} className="row" selected="0">
        {panes}
      </ContentSwitcher.Tabs>
    </div>
  );
};

export default HorizontalTabs;