import React, {useState, useRef, useEffect} from 'react';
import YouTube from 'react-youtube';
import PlayIcon from 'src/components/common/Icons/PlayIcon';
import s from './Video.module.scss';

const Video = (props) => {
  const {youtubeID, internalVideo, videoThumbnail, removeMarginBottom = 'false'} = props;
  const [ showPlayIcon, setShowPlayIcon ] = useState(true);
  const [ yt, setYT ] = useState(null);
  const internalVideoRef = useRef(null);

  const handlePlayButton = () => {
    setShowPlayIcon(false);
    (!!internalVideoRef && !!internalVideoRef.current) && internalVideoRef.current.play();
    !!yt && yt.playVideo();
  }

  return (
    <div className={`${s.root} ${!!removeMarginBottom && removeMarginBottom == 'true' ? s.noMarginBottom : ""}`}>
      {!!youtubeID && 
        <YouTube 
          className={s.youtubeVideo}
          videoId={youtubeID} 
          onReady={(event) => setYT(event.target)}
        />
      }
      {(!youtubeID && !!internalVideo) && 
        <video 
          ref={internalVideoRef}
          className={s.video}
          width="900" 
          height="533" 
          controls="controls" 
          poster={videoThumbnail?.url} 
          // onPause={() => setShowPlayIcon(true)} 
        >
          <source src={internalVideo?.url} type="video/mp4" />
        </video>
      }
      {(!!showPlayIcon && !!videoThumbnail) && <img src={videoThumbnail.url} className={s.videoThumbnail}/>}
      {!!showPlayIcon && <PlayIcon className={s.playIcon} onClick={handlePlayButton}/>}
    </div>
  );
};

export default Video;