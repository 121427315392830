import * as React from "react";

function Facebook(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M64 32C64 14.327 49.673 0 32 0S0 14.327 0 32c0 15.972 11.702 29.211 27 31.611V41.25h-8.125V32H27v-7.05c0-8.02 4.777-12.45 12.087-12.45 3.501 0 7.163.625 7.163.625V21h-4.035C38.24 21 37 23.467 37 25.997V32h8.875l-1.419 9.25H37v22.361C52.298 61.211 64 47.972 64 32"
          fill="#1877f2"
        />
        <path
          d="M44.456 41.25L45.875 32H37v-6.003C37 23.467 38.24 21 42.215 21h4.035v-7.875s-3.662-.625-7.163-.625C31.777 12.5 27 16.93 27 24.95V32h-8.125v9.25H27v22.361c1.629.256 3.299.389 5 .389s3.371-.133 5-.389V41.25z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

const MemoFacebook = React.memo(Facebook);
export default MemoFacebook;
