import React from 'react';

import s from './CategoryLinkPanels.module.scss';
import cx from 'classnames';

import SectionTitle from '../SectionTitle/SectionTitle';
import LinkPanel from '../LinkPanel/LinkPanel';
import HeroSlider from '../HeroSlider/HeroSlider';

class CategoryLinkPanels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.panels[0].id,
    };
    this._handleTabClick = this._handleTabClick.bind(this);
  }

  _handleTabClick = function (id, event) {
    this.setState({selected : id})
    event.preventDefault();
  };

  render() {
    let selectors = this.props.panels.map((el, index) => {
      return (
        <li key={index} style={{backgroundImage: `url(${el.tabImage?.url})`}} onClick={(e) => {this._handleTabClick(el.id, e)} } className={cx(s.categorySelector, {[`${s.selectedCategory}`]: el.id === this.state.selected})} >
          <h3 className={s.categoryTitle}>{el.tabs}</h3>
          <div className={s.arrow_box}></div>
        </li>
      )
    });
    let tabs = <ul className={s.categorySelectorHolder}>{selectors}</ul>;

    let panels = this.props.panels.map((el, index) => {
      let hero = <HeroSlider slideDuration={5000} slideSpeed={100} heroes={[{image: el.featuredImage, id: el.id, title: el.heading, description: el.subHeading, url: el.cta}]} />;
      let title = <SectionTitle title={el.linkPanelTitle} />
      let linkPanels = el.linkPanels?.map((l, x) => {
        return (
          <div key={x} className="column column-sm-4 column-xs-12">
            <LinkPanel {...l} />
          </div>
        )
      });

      return (
        <div key={index} className={cx(s.panel, {[`${s.selectedPanel}`]: el.id === this.state.selected})}>
          {hero}
          {title}
          <div className="row">
            {linkPanels}
          </div>
        </div>
      )
    });

    return (
        <div className={s.root}>
          {tabs}
          {panels}
        </div>
    );
  }
};

export default CategoryLinkPanels;