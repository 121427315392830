import React, { useState, useEffect } from 'react';
import s from './GoToTop.module.scss';
import cx from 'classnames';

const GoToTop = () => {
    const [ showGoToTop, setShowGoToTop ] = useState(false);
    const [ rightPos, setRightPos ] = useState(0);
    useEffect(() => {
        calRightPos();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', calRightPos);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', calRightPos);
        }
    }, [])
    
    const handleScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        setShowGoToTop(winScroll > 480);
    }

    const calRightPos = () => {
        let width = window.innerWidth;
        let container = document.getElementById('main');
        let containerClientRect = container.getBoundingClientRect();
        let containerRightPos = (width - containerClientRect.width) / 2;
        if(width > (containerClientRect.width + 226)){
            // 133 = 61 (icon width) + 53 (icon space from container)
            setRightPos(containerClientRect.left - 113);
        }else if(width > 768){
            setRightPos(containerRightPos);
        }else{
            setRightPos(20);
        }
    }

    return (
        !!showGoToTop && 
        <div 
            className={s.root} 
            style={{right: `${rightPos}px`}}
            onClick={(e) => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" className={s.arrowUpIcon}>
                <circle cx="30.8959" cy="30.8959" r="30.8959" transform="rotate(-90 30.8959 30.8959)" fill="#00030C"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M32.4004 39.6201L32.4004 24.8719L38.8515 31.3245C39.0917 31.5769 39.4249 31.7197 39.7733 31.7197C40.1218 31.7197 40.4549 31.5769 40.6952 31.3245C40.9475 31.0843 41.0903 30.7511 41.0903 30.4027C41.0903 30.0543 40.9475 29.7211 40.6952 29.4808L32.137 20.9211C31.5339 20.3932 30.6331 20.3932 30.0299 20.9211L21.4725 29.4808C21.2202 29.7211 21.0774 30.0543 21.0774 30.4027C21.0774 30.7511 21.2202 31.0843 21.4725 31.3245C21.7128 31.5769 22.046 31.7197 22.3944 31.7197C22.7428 31.7197 23.076 31.5769 23.3163 31.3245L29.7689 24.8719L29.7689 39.6201C29.7487 39.9741 29.8806 40.32 30.1313 40.5707C30.3821 40.8215 30.7279 40.9533 31.0819 40.9332C31.4369 40.9549 31.7842 40.8238 32.0362 40.5728C32.2882 40.3219 32.4207 39.9751 32.4004 39.6201L32.4004 39.6201Z" fill="white"/>
            </svg>
        </div>
    )
}

export default GoToTop;