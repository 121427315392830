import * as React from "react";

function CloseIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path
          d="M11.485 13.1L3 4.616l1.414-1.414 8.485 8.485 8.486-8.485 1.414 1.414-8.485 8.486 8.485 8.485L21.385 23l-8.486-8.485L4.414 23 3 21.586l8.485-8.485z"
          id="prefix__close"
        />
      </defs>
      <use
        fill="#000"
        fillRule="nonzero"
        transform="rotate(-90 12.9 13.1)"
        xlinkHref="#prefix__close"
      />
    </svg>
  );
}

const MemoCloseIcon = React.memo(CloseIcon);
export default MemoCloseIcon;
