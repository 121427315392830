import React from 'react';
import Download from '../Icons/Dowload';
import s from './Button.module.scss';
import cx from 'classnames';
import { checkIfFile } from 'src/helpers/utils';

const Button = ({
    text = 'label',
    href = '#',
    target = '_self',
    outline = false,
    theme = 'transparent', // transparent/red/black
    className = ""
}) => {
    return (
        <a href={href} target={target} className={cx(s.btn, s[outline ? 'outline' : ''], s[theme], className)}>
            <span>{text}</span>
            {checkIfFile(href) && <Download className={s.downloadIcon}/>}
        </a>
    )
}

export default Button;