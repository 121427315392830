import React from "react";
import Button from "src/components/common/Buttons/Button";
import s from "./HeroWithCTAs.module.scss";
import cx from "classnames";

const HeroWithCTAs = ({ title, cta1, cta2, backgroundImage }) => {
  return (
    <div
      className={cx(s.root)}
      style={{ backgroundImage: `url(${backgroundImage?.url})` }}
    >
      <h3 className={s.title}>{title}</h3>
      <div className={s.btnWrapper}>
        {!!cta1 && <Button {...cta1} outline={true} />}
        {!!cta2 && <Button {...cta2} theme="black" className={""} />}
      </div>
    </div>
  );
};

export default HeroWithCTAs;
