import * as React from "react";

function SearchIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path
          d="M4.464 16.717a8.612 8.612 0 01-2.538-6.13A8.61 8.61 0 014.464 4.46a8.644 8.644 0 016.13-2.534A8.64 8.64 0 0116.72 4.46c3.38 3.379 3.38 8.878 0 12.258-3.378 3.377-8.877 3.378-12.257 0zm21.522 7.902l-7.257-7.256c3.467-4.158 3.255-10.367-.646-14.266-4.13-4.13-10.85-4.129-14.98 0A10.52 10.52 0 000 10.587c0 2.83 1.102 5.49 3.103 7.491a10.56 10.56 0 007.49 3.097c2.411 0 4.82-.82 6.774-2.45l7.257 7.255 1.362-1.361z"
          id="prefix__search"
        />
      </defs>
      <use xlinkHref="#prefix__search" fillRule="evenodd" />
    </svg>
  );
}

export default SearchIcon;
