import * as React from "react";

function Youtube(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M62.663 16.557a8.041 8.041 0 00-5.659-5.695C52.013 9.516 32 9.516 32 9.516s-20.013 0-25.004 1.346a8.041 8.041 0 00-5.659 5.695C0 21.58 0 32.061 0 32.061s0 10.481 1.337 15.504a8.041 8.041 0 005.659 5.695C11.987 54.607 32 54.607 32 54.607s20.013 0 25.004-1.346a8.043 8.043 0 005.659-5.695C64 42.542 64 32.061 64 32.061s0-10.481-1.337-15.504zm-37.209 25.02V22.545l16.727 9.516z"
        fill="#ed3124"
      />
      <path d="M25.454 41.577l16.728-9.516-16.728-9.516z" fill="#fefefe" />
    </svg>
  );
}

const MemoYoutube = React.memo(Youtube);
export default MemoYoutube;
