import { forwardRef, useEffect, useRef, useState } from "react";
import LanguageSwitcher from "../LanguageSwitcher";
import LoadingWidget from "../LoadingWidget";
import GlobeIcon from "../Icons/Globe";
import SearchIcon from "../Icons/Search";
import AngleRightIcon from "../Icons/AngleRight";
import { useRouter } from "next/router";
import cx from "classnames";
import s from "./Header.module.scss";
import MemoCloseIcon from "../Icons/CloseIcon";
import Link from "next/link";
import $ from "jquery";
import EmailForm from "./EmailForm";

const Header = ({ location, logo, links, languageCode }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const router = useRouter();

  const handleMenuDrop = (item) => {
    if (showDropdown) {
      setShowDropdown(false);
      if (item && item.pageID === activeMenuItem.pageID) {
        setActiveMenuItem({});
      } else {
        setActiveMenuItem(item);
        setShowDropdown(true);
      }
      return;
    }
    setActiveMenuItem(item);
    setShowDropdown(true);
  };

  //closes dropdown when links are clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setActiveMenuItem({});
      setShowDropdown(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  //show/hide menu on scroll
  useEffect(() => {
    if (scrollY < 200) {
      setShowNavbar(true);
    }

    function handleScroll() {
      if (showDropdown) {
        return setShowNavbar(true);
      }
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
      if (currentScrollY <= scrollY || currentScrollY < 200) {
        setTimeout(() => setShowNavbar(true), 200);
      } else {
        setTimeout(() => setShowNavbar(false), 400);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, showDropdown, showNavbar]);

  // Focus search input when toggled open
  useEffect(() => {
    if (showSearch) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  return (
    <>
      <div className={s.red_border}></div>
      <div
        className={cx(s.root, s.desktop)}
        style={{
          transform: showNavbar ? "translateY(0)" : "translateY(-90px)",
        }}
      >
        <div className={s.navbar}>
          <div className={s.nav_left}>
            <span className={s.logo_link}>
              <Link href="/" aria-label="home">
                <img height="72" src={logo.url} alt="logo" />
              </Link>
            </span>
            <span className={s.site_title}>{location}</span>
          </div>
          <ul className={s.nav__links}>
            {!showSearch &&
              links &&
              links.map((link) => {
                const href =
                  link.name === "home" || link.name === "Accueil"
                    ? "/"
                    : link.href;
                return (
                  link.visible?.menu && (
                    <li
                      key={link.pageID}
                      className={
                        activeMenuItem?.pageID === link?.pageID
                          ? s.active
                          : null
                      }
                    >
                      {link.children ? (
                        <button onClick={() => handleMenuDrop(link)}>
                          {link.menuText}
                        </button>
                      ) : link.target === "_blank" || link.redirect ? (
                        <a href={link.href} target={link.target}>
                          {link.menuText}
                        </a>
                      ) : (
                        <a href={href}>{link.menuText}</a>
                      )}
                    </li>
                  )
                );
              })}
          </ul>
          <div className={s.nav_right}>
            {!showSearch && (
              <LanguageSwitcher
                locale={languageCode}
                className={s.lang_switcher}
              />
            )}
            {!showSearch && (
              <a
                href="https://www.mitsubishielectric.com/en/index.html"
                target="_blank"
                className={s.global_site_link}
                aria-label="Visit Mitsubishi Electric Global"
              >
                <GlobeIcon style={{ fontSize: "28px" }} />
                <span className="sr-only">
                  Visit Mitsubishi Electric Global
                </span>
              </a>
            )}
            <div className={s.search_container}>
              <div
                className={cx(s.search_input, showSearch ? s.active : s.hidden)}
                aria-hidden={!showSearch} //
              >
                <form action={`/${languageCode}/search`} method="get">
                  <label className={s.sr_only} htmlFor={"search"}>
                    Search
                  </label>
                  <input
                    type="text"
                    name="q"
                    id="search"
                    placeholder="Search Mitsubishi Electric"
                    autoFocus={showSearch}
                    tabIndex={showSearch ? 0 : -1}
                    ref={searchInputRef}
                  ></input>
                  <button
                    aria-hidden={true}
                    aria-label={"Submit"}
                    className={s.search_submit}
                    tabIndex={showSearch ? 0 : -1}
                    name="search_submit_button"
                    role="button"
                    type="submit"
                  >
                    <span className="sr-only">Submit Search</span>
                    <SearchIcon style={{ fontSize: "28px" }} />
                  </button>
                </form>
              </div>
              <button
                className={s.search_bar}
                onClick={() => setShowSearch(!showSearch)}
                aria-label={showSearch ? "Close Search" : "Open Search"}
              >
                {showSearch ? (
                  <MemoCloseIcon style={{ fontSize: "28px" }} />
                ) : (
                  <>
                    <AngleRightIcon
                      style={{
                        marginLeft: "-10px",
                        fontSize: "26px",
                        transform: "rotate(180deg)",
                        fill: "black",
                      }}
                    />
                    <SearchIcon
                     
                      style={{ fontSize: "28px" }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <DropdownMenu
          activeMenuItem={activeMenuItem}
          ref={dropdownRef}
          setShowDropdown={setShowDropdown}
          setActiveMenuItem={setActiveMenuItem}
          showDropdown={showDropdown}
        />
      </div>
      <EmailForm logo={logo} />
    </>
  );
};

const DropdownMenu = forwardRef(
  (
    {
      activeMenuItem,
      setActiveMenuItem,
      setShowDropdown,

      showDropdown,
    },
    ref
  ) => {
    const { menuText, href, target, children } = activeMenuItem;
    const [openChild, setOpenChild] = useState({});
    const [menuHeight, setMenuHeight] = useState(0);
    const router = useRouter();

    //dynamically set heigh on dropdown
    useEffect(() => {
      if (openChild && openChild.children) {
        setMenuHeight(openChild.children?.length ?? 0);
      } else {
        setMenuHeight(0);
      }
    }, [activeMenuItem, openChild]);

    //reset state on route change
    useEffect(() => {
      const handleRouteChange = () => {
        if (openChild) {
          setOpenChild({});
        }
        setMenuHeight(0);
      };

      router.events.on("routeChangeComplete", handleRouteChange);

      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }, [router.events]);

    return (
      <div
        ref={ref}
        className={s.dropdown__container}
        style={{
          transform: showDropdown ? "translateY(0)" : "translateY(-100%)",
        }}
      >
        {showDropdown && (
          <div
            className={s.dropdown__overlay}
            onClick={() => {
              setActiveMenuItem({});
              setShowDropdown(false);
            }}
          ></div>
        )}

        <div
          className={s.dropdown__container_inner}
          style={{
            transform: showDropdown ? "translateY(0)" : "translateY(-100%)",
          }}
        >
          {showDropdown && (
            <>
              <button
                onClick={() => {
                  setShowDropdown(false), setActiveMenuItem({});
                }}
                className={s.close_btn}
              >
                <MemoCloseIcon width={24} height={24} />
                <span className={s.sr_only}>close</span>
              </button>
              <div className={s.dropdown__left}>
                <h2>{menuText}</h2>
                {menuText &&
                  (target === "_blank" || activeMenuItem.redirect ? (
                    <a
                      href={href}
                      target={target}
                      className={cx(s.link_button)}
                    >
                      {menuText}
                    </a>
                  ) : (
                    <span className={cx(s.link_button)}>
                      <a href={href}>{menuText}</a>
                    </span>
                  ))}
              </div>
              <div
                className={s.dropdown__right}
                style={{
                  minHeight: `${menuHeight * 47 + 50}px`,
                  transition: "ease-in-out 400ms all",
                }}
              >
                {children && children.length > 0 && (
                  <ul>
                    {children.map((item) => (
                      <MenuLink
                        key={item.pageID}
                        item={item}
                        openChild={openChild}
                        setOpenChild={setOpenChild}
                        level={0}
                        children={item?.children}
                      />
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

const MenuLink = ({ item, setOpenChild, openChild, level, children }) => {
  const handleClick = () => {
    if (children) {
      setOpenChild(item?.pageID === openChild?.pageID ? null : item);
    } else {
      if (item.target === "_blank") {
        window.open(item.href);
      } else {
        setIsLoading(true);
        router.push(item.href).then(() => {
          setShowMenu(false);
          setActiveMenuItem({});
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <li className={cx(level > 1 && s.grandchild_link)}>
      {item?.redirect ? (
        <a
          className={cx(
            openChild?.pageID === item?.pageID ? s.active : s.inactive
          )}
          href={item.href}
          target={item.target}
        >
          {item.menuText}
        </a>
      ) : children && children.length > 0 && level < 1 ? (
        <button
          className={cx(
            openChild?.pageID === item?.pageID ? s.active : s.inactive
          )}
          onClick={() => handleClick()}
        >
          {item.menuText}
          {<AngleRightIcon />}
        </button>
      ) : (
        <a
          href={item?.href}
          target={item?.target}
          className={cx(
            openChild?.pageID === item?.pageID ? s.active : s.inactive
          )}
        >
          {item.menuText}
        </a>
      )}

      <ul
        className={level < 1 && s.list_right}
        style={{
          display:
            children &&
            children.length > 0 &&
            openChild?.path &&
            item.href.includes(openChild?.name) &&
            children
              ? "block"
              : "none",
          left:
            children &&
            children.length > 0 &&
            openChild?.path &&
            item.href.includes(openChild?.name) &&
            children
              ? "50%"
              : "100%",
          transition: "ease-in-out 400ms left",
        }}
      >
        {children && children.length > 0 && (
          <div>
            {level === 0 && (
              <li>
                {item.target === "_blank" || item.redirect ? (
                  <a href={item.href} target={item.target}>
                    {item.menuText}
                  </a>
                ) : (
                  <a href={item.href}>{item.menuText}</a>
                )}
              </li>
            )}

            {children.map((childItem) => (
              <MenuLink
                key={childItem.pageID}
                item={childItem}
                openChild={openChild}
                setOpenChild={setOpenChild}
                level={level + 1}
                children={childItem?.children}
              />
            ))}
          </div>
        )}
      </ul>
    </li>
  );
};

export default Header;
