import s from "./IconListWithSlider.module.scss";
import Slick from "react-slick";

const Item = ({ feature }) => {
  return (
    <dl className={s.item}>
      <div className={s.item_left}>
        {feature.icon && (
          <img src={feature.icon.url} alt={feature.icon.alt || feature.icon.label} />
        )}
      </div>
      <div className={s.item_right}>
        <dt>{feature?.title}</dt>
        <dd>{feature?.description}</dd>
      </div>
    </dl>
  );
};

const IconListWithSlider = ({ backgroundImage, featureList }) => {

  const settings = {
    autoplay: false,
    arrows: false,
    dots: true,
    slidesToShow: 1,
  };

  return (
    <div
      className={s.root}
      style={{
        backgroundImage: backgroundImage && `url(${backgroundImage?.url})`,
      }}
    >
      {/* Renders Desktop Icon Grid */}
      <div className={s.icon_grid}>
        {featureList &&
          featureList.map((feature) => (
            <Item key={feature?.contentID} feature={feature?.fields} />
          ))}
      </div>
      {/* Renders Mobile Icon Slider */}
      <Slick {...settings} className={s.slider}>
        {featureList &&
          featureList.map((feature) => (
            <div>
              <Item key={feature?.contentID} feature={feature?.fields} />
            </div>
          ))}
      </Slick>
    </div>
  );
};

export default IconListWithSlider;
