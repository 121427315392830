import * as React from "react";

function MenuBarsIcon(props) {
  return (
    <svg {...props}>
      <defs>
        <path
          d="M3 14v-2h20.11v2H3zm0 5v-2h20.11v2H3zM3 9V7h20.11v2H3z"
          id="prefix__a"
        />
      </defs>
      <g transform="translate(-3 -7)" fill="none" fillRule="evenodd">
        <path d="M0 26V0h26v26z" />
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#prefix__a" />
        <g mask="url(#prefix__b)" fill="#000">
          <path d="M26 26H0V0h26z" />
        </g>
      </g>
    </svg>
  );
}

const MemoMenuBarsIcon = React.memo(MenuBarsIcon);
export default MemoMenuBarsIcon;
