import React from 'react';
import s from './HomeOwners_HighlightItem.module.scss';

const HomeOwners_HighlightItem = ({title, description, image}) => {
  // console.log({props})
  return (
    <div className="row homeowners_highlightitem">
      <div className="column column-xs-3">
        <p>
          {!!image && <img src={image.url} alt={image.label}/>}
        </p>
      </div>

      <div className="column column-middle column-xs-9">
        <h4 className={s.title}>
            {title}
        </h4>
        <div dangerouslySetInnerHTML={{__html: description}}/>
      </div>
    </div>
  );
};

export default HomeOwners_HighlightItem;
