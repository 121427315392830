import React from 'react';

import s from './CallToAction.module.scss';
import cx from 'classnames';

const CallToAction = ({text, href, target, children, tag, className}) => {
  const Tag = !!href ? 'a' : 'div';
  return (
      <Tag href={href} target={target} className={cx(s.root, className)}>{text || children}</Tag>
  );
};

export default CallToAction;