import React, { useEffect } from 'react';
import IconsListingItem from './IconsListingItem';
import s from './IconsListing.module.scss';
import cx from 'classnames';
import { orderItemsByAgilityOrder } from 'src/helpers/utils';

const IconsListing = ({iconsList = []}) => {
  const list = orderItemsByAgilityOrder(iconsList);

  useEffect(() => {
    function setItemsTitleHeight() {
      // set all items title to have the same height (max-content height)
      let itemTitles = document.querySelectorAll('.icons-listing .item-title');
      if(!!itemTitles && !!itemTitles.length){
        itemTitles = [...itemTitles];
        const allHeights = itemTitles.map((ele) => ele.getBoundingClientRect().height);
        const max = window.innerWidth > 768 ? Math.max(...allHeights) + 'px' : 'auto';
        itemTitles.forEach(ele => {
          ele.style.height = max;
        })
      }
      
    }
    setItemsTitleHeight();
    window.addEventListener('resize', setItemsTitleHeight);
    return () => {
      window.removeEventListener('resize', () => setItemsTitleHeight);
    };
  }, [])
  
  return (
    <>
      {(!!list && !!list.length) &&
        <div className={cx(s.root, 'icons-listing')}>
          {list.map((item, idx)=> <IconsListingItem key={idx} {...item.fields}/>)}
        </div>
      }
    </>
  );
};

export default IconsListing;