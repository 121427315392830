import React from 'react';
import Button from 'src/components/common/Buttons/Button';
import s from './LearnMorePanel.module.scss';
import cx from 'classnames';

const LearnMorePanel = ({title, cta1, cta2}) => {

  return (
      <div className={cx(s.root)}>
        <h3 className={s.title}>{title}</h3>
        {!!cta1 && <Button {...cta1} outline={true} className={s.lButton}/>}
        {!!cta2 && <Button {...cta2} theme="black" className={s.lButton}/>}
      </div>
  );
};

export default LearnMorePanel;