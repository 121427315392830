import React, { useEffect, useRef } from "react";
import { transformToLocaleHref } from "src/helpers/utils";

const RichTextArea = ({ textblob, locale = "en", className = "" }) => {
  const divRef = useRef();

  useEffect(() => {
    if (!textblob || (!divRef && !divRef.current)) return;
    const range = document.createRange();
    const documentFragment = range.createContextualFragment(textblob);
    const scripts = documentFragment.querySelectorAll("script");
    const anchors = documentFragment.querySelectorAll("a");
    !!scripts &&
      !!scripts.length &&
      scripts.forEach((element) => {
        let src = element.src;
        let script = document.createElement("script");
        if (!!src) {
          script.src = src;
          if (src.includes("featherlight")) {
            // featherlight script depends on jquery
            jQueryIntervalBeforeLoadingScript(script);
          } else {
            document.head.appendChild(script);
          }
        } else {
          jQueryIntervalBeforeLoadingScript(script, element);
        }
        element.parentNode?.removeChild(element);
      });

    !!anchors &&
      !!anchors.length &&
      anchors.forEach((element) => {
        let href = transformToLocaleHref(element.href, locale);
        element.href = href;
      });
    // console.log(documentFragment)
    divRef.current.innerHTML = "";
    divRef.current.appendChild(documentFragment);
  }, [textblob, divRef]);

  const jQueryIntervalBeforeLoadingScript = (script, element = {}) => {
    let count = 0;
    var waitForJQuery = setInterval(function () {
      count += 1;
      if (
        typeof $ != "undefined" ||
        typeof jQuery != "undefined" ||
        count == 5
      ) {
        if (element?.innerHTML) {
          script.innerHTML = element.innerHTML;
        }
        document.head.appendChild(script);
        clearInterval(waitForJQuery);
      }
    }, 100);
  };

  // const convertHttpToHttps = (domNode, field) => {
  //     let _field = domNode?.attribs[field];
  //     if(_field?.indexOf('http://') == 0){
  //         domNode.attribs[field] = _field?.replace('http://','https://');
  //     }
  //     return domNode;
  // }

  return (
    <div
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: textblob }}
      className={className}
    />
  );
};

export default RichTextArea;
