import React from "react";
import LabelsContext from "src/context/LabelsContext";
import s from "./JetTowelCalculator.module.scss";
import cx from "classnames";

import Cta from "../CallToAction/CallToAction";

const Calcinput = ({ label, total, name, value, step, unit, changeHandler }) => {
  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-7 column-sm-5">
          <label className={s.label}>{label}</label>
        </div>
        <div className="column column-xs-3 column-sm-5">
          <input
            className={s.input}
            type="number"
            min="0"
            step={step || 1}
            value={value}
            name={name}
            onChange={changeHandler}
          />
        </div>
        <div className="column column-xs-2 column-sm-2">
          <div className={s.unit}>{unit}</div>
        </div>
      </div>
    </div>
  );
};

const Total = ({ label, label2 }) => {
  return (
    <div className={cx(s.total, s.itemgroup)}>
      <div className="row">
        <div className="column column-xs-6">
          <div className={s.totallabel}>{label}</div>
        </div>
        <div className="column column-xs-5">
          <div className={s.totalamount}>{label2}</div>
        </div>
      </div>
    </div>
  );
};

const OperatingCostRow = ({
  duration,
  perdaycosts,
  sup,
  multiplier,
  _locale,
  durationAmount,
  firstItem,
}) => {
  let formattedDuration = duration;

  if (duration === "undefined*" && _locale === "fr") {
    formattedDuration = "$ non défini*";
  }

  if (
    (/^\d+ undefined$/.test(duration) || /^\d+ undefineds$/.test(duration)) &&
    _locale === "fr"
  ) {
    formattedDuration = `tranche de ${durationAmount} $ non défini*`;
  }

  return (
    <div className={s.OperatingCostRow}>
      <div className="row">
        <div className="column column-xs-4">
          {_locale === "en" ? "Cost per" : "Coût par"} {formattedDuration}
          <sup>{sup}</sup>{" "}
          {_locale === "en" || (_locale === "fr" && firstItem) ? "$" : ""}
        </div>
        <div className={cx("column column-xs-3", s.right)}>
          {(perdaycosts.paper * multiplier).toFixed(2)}
        </div>
        <div className={cx("column column-xs-3", s.right)}>
          {(perdaycosts.dryer * multiplier).toFixed(2)}
        </div>
        <div className={cx("column column-xs-2", s.right)}>
          {(perdaycosts.jet * multiplier).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const TotalCostRow = ({
  duration,
  perdaycosts,
  sup,
  multiplier,
  basecosts,
  _locale,
  durationAmount,
  firstItem,
}) => {
  let formattedDuration = duration;

  if (duration === "undefined*" && _locale === "fr") {
    formattedDuration = "$ non défini*";
  }

  if (
    (/^\d+ undefined$/.test(duration) || /^\d+ undefineds$/.test(duration)) &&
    _locale === "fr"
  ) {
    formattedDuration = `tranche de ${durationAmount} $ non défini*`;
  }

  return (
    <div className={s.OperatingCostRow}>
      <div className="row">
        <div className="column column-xs-4">
          {_locale === "en" ? "Cost per" : "Coût par"} {formattedDuration}{" "}
          <sup>{sup}</sup>{" "}
          {_locale === "en" || (_locale === "fr" && firstItem) ? "$" : ""}
        </div>
        <div className={cx("column column-xs-3", s.right)}>
          {(
            parseFloat((perdaycosts.paper * multiplier).toFixed(2)) +
            parseFloat(basecosts.paper)
          ).toFixed(2)}
        </div>
        <div className={cx("column column-xs-3", s.right)}>
          {(
            parseFloat((perdaycosts.dryer * multiplier).toFixed(2)) +
            parseFloat(basecosts.dryer)
          ).toFixed(2)}
        </div>
        <div className={cx("column column-xs-2", s.right)}>
          {(
            parseFloat((perdaycosts.jet * multiplier).toFixed(2)) +
            parseFloat(basecosts.jet)
          ).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

class JetTowelCalculator extends React.Component {
  static contextType = LabelsContext;

  constructor(props) {
    super(props);
    this.state = {
      paperInitial: 100, //$
      paperCostPerSheet: 0.006, //$
      paperNumSheetsPerUse: 2,
      paperUsesPerDay: 1000,
      electricalCostPerKW: 0.07, //$
      dryerInitCost: 300,
      dryerConsumption: 2.5, //KW
      dryerDryingTime: 45, //Seconds
      dryerUsesPerDay: 1000,
      jetInitCost: 1300, //$
      jetConsumption: 0.65, //KW
      jetDryingTime: 10, //Seconds
      jetUsesPerDay: 1000,
    };
    let locale = this.props.locale || "default";
    this._locale = locale === "default" ? "en" : locale;
    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(event) {
    let stateCopy = Object.assign({}, this.state);
    stateCopy[event.target.name] = event.target.value;
    this.setState(stateCopy);
  }

  _convertSecondsToHours(seconds) {
    return seconds / 3600;
  }

  render() {
    let paperCostPerOneTimeUse =
      this.state.paperCostPerSheet.toFixed(3) * this.state.paperNumSheetsPerUse;
    let dryerCostPerOneTimeUse =
      (this.state.dryerConsumption *
        this.state.electricalCostPerKW *
        this.state.dryerDryingTime) /
      3600;
    let jetCostPerOneTimeUse =
      (this.state.jetConsumption *
        this.state.electricalCostPerKW *
        this.state.jetDryingTime) /
      3600;

    const costs = {
      paper: paperCostPerOneTimeUse * this.state.paperUsesPerDay,
      dryer: dryerCostPerOneTimeUse * this.state.paperUsesPerDay,
      jet: jetCostPerOneTimeUse * this.state.paperUsesPerDay,
    };

    let basecosts = {
      paper: this.state.paperInitial,
      dryer: this.state.dryerInitCost,
      jet: this.state.jetInitCost,
    };

    let month = 22;
    let year = 22 * 12;

    let paybackPaper =
      (this.state.jetInitCost - this.state.paperInitial) %
      (costs.jet * month - costs.paper * month);

    let formattedPaybackMonths = paybackPaper.toLocaleString(
      this._locale === "en" ? "en-US" : "fr-FR",
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    );

    let formattedPaybackYears = (paybackPaper % 12).toLocaleString(
      this._locale === "en" ? "en-US" : "fr-FR",
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    );

    let paybackDryer =
      (this.state.jetInitCost - this.state.dryerInitCost) %
      (costs.jet * month - costs.dryer * month);

    let formattedPaybackDryerMonths = paybackDryer.toLocaleString(
      this._locale === "en" ? "en-US" : "fr-FR",
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    );

    let formattedPaybackDryerYears = (paybackDryer % 12).toLocaleString(
      this._locale === "en" ? "en-US" : "fr-FR",
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    );

    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;

    return (
      <div className="calculator">
        <div className="row">
          <div className="column column-xs-12 column-sm-4 column-md-5">
            <div className={s.inputPanel}>
              <br />
              <h3>
                {this._locale === "en"
                  ? "General Information"
                  : "RENSEIGNEMENTS GÉNÉRAUX"}
              </h3>
              <Calcinput
                label={
                  this._locale === "en"
                    ? "Uses Per Day"
                    : "Nombre d'utilisations par jour"
                }
                value={this.state.paperUsesPerDay}
                name="paperUsesPerDay"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={
                  this._locale === "en"
                    ? "Electrical Cost per/KW"
                    : "Coût de l'électricité par kW"
                }
                unit="$"
                step="0.01"
                value={this.state.electricalCostPerKW}
                name="electricalCostPerKW"
                changeHandler={this._handleChange}
              />
              <br />
              <h3>
                {this._locale === "en"
                  ? "Paper Towel Data"
                  : "RENSEIGNEMENTS SUR LES ESSUIE-MAINS"}
              </h3>
              <Calcinput
                label={
                  this._locale === "en"
                    ? "Paper Initial Cost"
                    : "Coût initial des essuie-mains"
                }
                unit="$"
                value={this.state.paperInitial}
                name="paperInitial"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={
                  this._locale === "en" ? "Paper Cost Per Sheet" : "Coût par feuille"
                }
                unit="$"
                step="0.001"
                value={this.state.paperCostPerSheet}
                name="paperCostPerSheet"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={
                  this._locale === "en"
                    ? "Number of sheets used at one time"
                    : "Nombre de feuilles utilisées à la fois"
                }
                value={this.state.paperNumSheetsPerUse}
                name="paperNumSheetsPerUse"
                changeHandler={this._handleChange}
              />
              <Total
                label={
                  this._locale === "en" ? "Cost Per One Time Use" : "Coût par utilisation"
                }
                label2={paperCostPerOneTimeUse.toFixed(5)}
              />
              <br />
              <h3>
                {this._locale === "en"
                  ? " Conventional Electric Dryer Data Input"
                  : "DONNÉES SUR LE SÈCHE-MAINS TRADITIONNEL"}
              </h3>
              <Calcinput
                label={this._locale === "en" ? "Initial Cost" : "Coût initial"}
                unit="$"
                value={this.state.dryerInitCost}
                name="dryerInitCost"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={this._locale === "en" ? "Consumption (KW)" : "Consommation (kW)"}
                step="0.01"
                value={this.state.dryerConsumption}
                name="dryerConsumption"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={
                  this._locale === "en" ? "Average drying time" : "Temps de séchage moyen"
                }
                value={this.state.dryerDryingTime}
                name="dryerDryingTime"
                changeHandler={this._handleChange}
              />
              <Total
                label={
                  this._locale === "en"
                    ? "Consumption cost for one time use"
                    : "Coût par utilisation"
                }
                label2={dryerCostPerOneTimeUse.toFixed(5)}
              />
              <h3>
                {this._locale === "en"
                  ? " Jet Towel Data Input"
                  : "RENSEIGNEMENTS SUR LE JET TOWEL"}
              </h3>
              <Calcinput
                label={this._locale === "en" ? "Initial Cost" : "Coût initial"}
                unit="$"
                value={this.state.jetInitCost}
                name="jetInitCost"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={this._locale === "en" ? "Consumption (KW)" : "Consommation (kW)"}
                step="0.01"
                value={this.state.jetConsumption}
                name="jetConsumption"
                changeHandler={this._handleChange}
              />
              <Calcinput
                label={
                  this._locale === "en" ? "Average drying time" : "Temps de séchage moyen"
                }
                value={this.state.jetDryingTime}
                name="jetDryingTime"
                changeHandler={this._handleChange}
              />
              <Total
                label={
                  this._locale === "en"
                    ? "Consumption cost for one time use"
                    : "Coût par utilisation"
                }
                label2={jetCostPerOneTimeUse.toFixed(5)}
              />
            </div>
          </div>
          <div className="column column-xs-12 column-sm-8 column-md-7">
            <br />
            <div className={s.OperatingCostTable}>
              <div className={s.OperatingCostRow}>
                <h3>
                  {this._locale === "en"
                    ? "Jet Towel Payback Time"
                    : "DÉLAI DE RÉCUPÉRATION DU PRIX DU JET TOWEL"}
                </h3>
                <p>
                  <strong>
                    {this._locale === "en"
                      ? "When compared to paper towel"
                      : "Par rapport aux essuie-mains :"}
                  </strong>{" "}
                  <span className={s.red}>
                    {" "}
                    {formattedPaybackMonths} {this._locale === "en" ? "Months" : "mois"} /{" "}
                    {formattedPaybackYears} {this._locale === "en" ? "Years" : "ans"}
                  </span>{" "}
                </p>
                <p>
                  <strong>
                    {this._locale === "en"
                      ? "When compared to conventional dryers"
                      : "Par rapport aux sèche-mains traditionnels :"}
                  </strong>{" "}
                  <span className={s.red}>
                    {" "}
                    {formattedPaybackDryerMonths}{" "}
                    {this._locale === "en" ? "Months" : "mois"} /{" "}
                    {formattedPaybackDryerYears} {this._locale === "en" ? "Years" : "ans"}
                  </span>{" "}
                </p>
              </div>
            </div>
            <div className={s.OperatingCostTable}>
              <div className={s.OperatingCostRow}>
                <div className="row">
                  <div className="column column-xs-4">
                    <h3>
                      {this._locale === "en" ? "Operating" : "Coût de"}
                      <br />
                      {this._locale === "en" ? "Cost" : "fonctionnement"}
                    </h3>
                  </div>
                  <div className={cx("column column-xs-3", s.right)}>
                    <h3>{this._locale === "en" ? "Paper Towel" : "Essuie-mains"}</h3>
                  </div>
                  <div className={cx("column column-xs-3", s.right)}>
                    <h3>
                      {this._locale === "en"
                        ? "Electric Dryer"
                        : "Sèche-mains électrique"}
                    </h3>
                  </div>
                  <div className={cx("column column-xs-2", s.right)}>
                    <h3>{this._locale === "en" ? "Jet Towel" : "Jet Towel"}</h3>
                  </div>
                </div>
              </div>
              <OperatingCostRow
                firstItem={true}
                perdaycosts={costs}
                duration={labels.dayLabel}
                multiplier={1}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`${labels.monthLabel}*`}
                multiplier={month}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`1 ${labels.yearLabel}`}
                durationAmount={1}
                multiplier={1 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`2 ${labels.yearLabel}s`}
                durationAmount={2}
                multiplier={2 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`3 ${labels.yearLabel}s`}
                durationAmount={3}
                multiplier={3 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`4 ${labels.yearLabel}s`}
                durationAmount={4}
                multiplier={4 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`5 ${labels.yearLabel}s`}
                durationAmount={5}
                multiplier={5 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`6 ${labels.yearLabel}s`}
                durationAmount={6}
                multiplier={6 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`7 ${labels.yearLabel}s`}
                durationAmount={7}
                multiplier={7 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`8 ${labels.yearLabel}s`}
                durationAmount={8}
                multiplier={8 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`9 ${labels.yearLabel}s`}
                durationAmount={9}
                multiplier={9 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`10 ${labels.yearLabel}s`}
                durationAmount={10}
                multiplier={10 * year}
                _locale={this._locale}
              />
              <OperatingCostRow
                perdaycosts={costs}
                duration={`11 ${labels.yearLabel}s`}
                durationAmount={11}
                multiplier={11 * year}
                _locale={this._locale}
              />
            </div>
            <div className={s.OperatingCostTable}>
              <div className={s.OperatingCostRow}>
                <div className="row">
                  <div className="column column-xs-4">
                    <h3>
                      {this._locale === "en" ? "Total" : "Coût"}
                      <br />
                      {this._locale === "en" ? "Cost" : "total"}
                    </h3>
                  </div>
                  <div className={cx("column column-xs-3", s.right)}>
                    <h3>{this._locale === "en" ? "Paper Towel" : "Essuie-mains"}</h3>
                  </div>
                  <div className={cx("column column-xs-3", s.right)}>
                    <h3>
                      {this._locale === "en"
                        ? "Electric Dryer"
                        : "Sèche-mains électrique"}
                    </h3>
                  </div>
                  <div className={cx("column column-xs-2", s.right)}>
                    <h3>{this._locale === "en" ? "Jet Towel" : "Jet Towel"}</h3>
                  </div>
                </div>
              </div>
              <TotalCostRow
                firstItem={true}
                basecosts={basecosts}
                perdaycosts={costs}
                duration={labels.dayLabel}
                multiplier={1}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`${labels.monthLabel}*`}
                multiplier={month}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`1 ${labels.yearLabel}`}
                durationAmount={1}
                multiplier={1 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`2 ${labels.yearLabel}s`}
                durationAmount={2}
                multiplier={2 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`3 ${labels.yearLabel}s`}
                durationAmount={3}
                multiplier={3 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`4 ${labels.yearLabel}s`}
                durationAmount={4}
                multiplier={4 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`5 ${labels.yearLabel}s`}
                durationAmount={5}
                multiplier={5 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`6 ${labels.yearLabel}s`}
                durationAmount={6}
                multiplier={6 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`7 ${labels.yearLabel}s`}
                durationAmount={7}
                multiplier={7 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`8 ${labels.yearLabel}s`}
                durationAmount={8}
                multiplier={8 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`9 ${labels.yearLabel}s`}
                durationAmount={9}
                multiplier={9 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`10 ${labels.yearLabel}s`}
                durationAmount={10}
                multiplier={10 * year}
                _locale={this._locale}
              />
              <TotalCostRow
                basecosts={basecosts}
                perdaycosts={costs}
                duration={`11 ${labels.yearLabel}s`}
                durationAmount={11}
                multiplier={11 * year}
                _locale={this._locale}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JetTowelCalculator;
