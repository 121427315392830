import React from "react";

import s from "./Breadcrumb.module.scss";
import cx from "classnames";

const Breadcrumb = ({ home, pages }) => {
  return (
    <div className="row">
      <div className="column column-xs-12">
        <ul className={s.root}>
          <li className={s.item}>
            <a className={s.link} href={home.href}>
              {home.menuText}
            </a>
          </li>
          {pages.map((p, index) => (
            <li key={index} className={s.item}>
              <a className={s.link} href={p.href} target={p.target}>
                {p.menuText}{" "}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
