import React from "react";

const PlayIcon = (props) => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="29.7373" cy="29.7373" r="29.7373" fill="#00030C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M26.5588 39.1473L36.8286 30.1875C36.9937 30.0428 37.0883 29.8339 37.0883 29.6144C37.0883 29.3949 36.9937 29.1861 36.8286 29.0413L26.5558 20.0274C26.3305 19.8331 26.0127 19.7878 25.7421 19.9113C25.4715 20.0348 25.2975 20.3046 25.2968 20.6021L25.2968 38.5735C25.2969 38.8722 25.4714 39.1431 25.7432 39.2667C26.015 39.3902 26.334 39.3435 26.559 39.1473L26.5588 39.1473Z" fill="white"/>
        </svg>
    )
}

export default PlayIcon;