import * as React from "react";

function AngleRightIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path
          id="prefix__angle_right"
          d="M7.769 8.233L6.354 9.646l7.699 7.708 7.715-7.707-1.413-1.415-6.3 6.293z"
        />
      </defs>
      <use
        fillRule="nonzero"
        transform="rotate(-90 14.06 12.793)"
        xlinkHref="#prefix__angle_right"
      />
    </svg>
  );
}

const MemoAngleRightIcon = React.memo(AngleRightIcon);
export default AngleRightIcon;
