import React from "react";
import LabelsContext from "src/context/LabelsContext";
import LanguageSwitcher from "../LanguageSwitcher";
import s from "./../Header/Header.module.scss";
import cx from "classnames";
import axios from "axios";
import _ from "lodash";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";

import SectionTitle from "/src/components/agility-pageModules/SectionTitle/SectionTitle";

const TreeNav = ({ item, childtype, clickHandler, openItem, isMobileMenu }) => {
  const _childtype_class =
    item.children == undefined
      ? "parent"
      : childtype == "parent"
      ? "child"
      : "child_" + childtype;
  const _has_children_class = item.children == undefined ? "" : s.hasChildren;
  const _is_active_class =
    item.isCurrent || item.isParentOfCurrent ? s.active : "";

  const _is_active = item.pageID == openItem;

  const _children =
    item.children == undefined
      ? false
      : item.children.map((el, index) => {
          return (
            <TreeNav
              isMobileMenu={isMobileMenu}
              clickHandler={clickHandler}
              key={index}
              childtype={_childtype_class}
              item={el}
            />
          );
        });

  return (
    <li
      key={item.href}
      onClick={(e) => clickHandler(item, e, childtype)}
      className={cx(s.listItem, [
        childtype,
        _has_children_class,
        _is_active_class,
      ])}
    >
      <a
        href={item.href}
        onClick={(e) => {
          if (!!item.children && isMobileMenu && childtype == "parent") {
            e.preventDefault();
          }
        }}
        target={item.target}
        title={item.menuText}
      >
        {item.menuText}
      </a>
      {!!item.children && (
        <ul
          className={cx(null, { [`${s.mobileOpen}`]: item.pageID == openItem })}
        >
          {_is_active && isMobileMenu && (
            <li
              key={item.href}
              onClick={(e) => clickHandler(item, e, childtype + "child")}
              className={cx(s.listItem, [
                childtype,
                _has_children_class,
                _is_active_class,
              ])}
            >
              <a
                className={s.onlymobile}
                href={item.href}
                title={item.menuText}
              >
                {item.menuText} TOP
              </a>
            </li>
          )}
          {_children}
        </ul>
      )}
    </li>
  );
};

const Input = ({ label, type, required, name, onChange, value }) => {
  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-5">
          <label className={s.label}>
            {label} {required && <span className={s.required}>*</span>}
          </label>
        </div>
        <div className="column column-xs-7">
          <input
            onChange={(e) => onChange(name, e.target.value)}
            value={value}
            className={s.input}
            type={type || "text"}
            name={name}
            height="150"
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

const Textarea = ({ label, type, required, name, onChange }) => {
  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-12">
          <label className={s.label}>
            {label} {required && <span className={s.required}>*</span>}
          </label>
        </div>
        <div className="column column-xs-12">
          <textarea
            onChange={(e) => onChange(name, e.target.value)}
            className={s.input}
            name={name}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

const Select = ({
  label,
  name,
  options,
  required,
  onChange,
  value,
  labels,
}) => {
  const _options = options.map((el, index) => {
    return (
      <option key={index} value={el.value}>
        {el.text}
      </option>
    );
  });

  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-12 column-sm-5">
          <label className={s.label}>
            {label} {required && <span className={s.required}>*</span>}
          </label>
        </div>
        <div className="column column-xs-12 column-sm-7">
          <div className={s.selectwrapper}>
            <select
              value={value}
              className={"select"}
              onChange={(e) => onChange(name, e.target.value)}
              required={required}
              name={name}
            >
              <option value="">{labels.dropDownSelectDefaultText}</option>
              {_options}
            </select>
            <div className={s.downarrow}>&#9660;</div>
          </div>
        </div>
      </div>
    </div>
  );
};

class _EmailForm extends React.Component {
  static contextType = LabelsContext;

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      openItem: null,
      fixed: false,
      title: "",
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      message: "",
      message_category: "",
      product_category: "",
      location: "",
      subject: "",
      contactSubmitting: false,
      isMobileMenu: false,
      promo: false,
    };

    this._handleClick = this._handleClick.bind(this);
    this._navToggle = this._navToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._formPreventDefault = this._formPreventDefault.bind(this);
    this.reCaptchaRef = React.createRef();
  }

  _handleClick(item, e, childtype) {
    e.stopPropagation();
    if (childtype == "parent") {
      const itemOpen = this.state.openItem == item.pageID ? null : item.pageID;
      this.setState({
        openItem: itemOpen,
      });
    }
  }

  _navToggle() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  _validate() {
    let regex = {
      email:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  _handleScroll() {
    if (window.scrollY > 74) {
      this.setState({ fixed: true });
    } else {
      this.setState({ fixed: false });
    }
  }

  _formPreventDefault(e) {
    e.preventDefault();
  }

  email_to = () => {
    switch (this.state.message_category) {
      case "Accessibility Standards Inquiry":
        return "info@mitsubishielectric.ca";
        break;
      case "Employment":
        return "HumanResources@mitsubishielectric.ca";
        break;
      case "Product Pre-Sales Inquiry":
        switch (this.state.product_category) {
          case "Digital Signage":
            return "Displays@mitsubishielectric.ca";
            break;
          case "Display Wall":
            return "Displays@mitsubishielectric.ca";
            break;
          case "Energy Recovery and Ventillation":
            return "HVAC@mitsubishielectric.ca";
            break;
          case "Hand Dryers":
            return "JetTowel@mitsubishielectric.ca";
            break;
          case "Heating and Cooling":
            return "HVAC@mitsubishielectric.ca";
            break;
          case "Imaging Printers":
            return "Printers@mitsubishielectric.ca";
            break;
          case "Photo Printers":
            return "Printers@mitsubishielectric.ca";
            break;
          case "Stadium and Arena Displays":
            return "Displays@mitsubishielectric.ca";
            break;
          default:
            return "info@mitsubishielectric.ca";
            break;
        }
        break;
      case "Product Post Sales Support & Inquiry":
        return "info@mitsubishielectric.ca";
        break;
      case "Website Feedback":
        return "info@mitsubishielectric.ca";
        break;
      case "External Whistle Blower Report":
        return "MESCA-WB-Report@mitsubishielectric.ca";
        break;
      case "Other":
        return "info@mitsubishielectric.ca";
        break;
      default:
        return "info@mitsubishielectric.ca";
        break;
    }
  };

  _handleSubmit(e) {
    if (!this.state.reCaptcha) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // Process reCAPTCHA verification
      axios
        .post("/api/recaptcha-verify", { captchaValue: this.state.reCaptcha })
        .then(
          (response) => {
            this.reCaptchaRef.current.reset();
            // console.log('response: ', response);

            const { labels } = this.context;
            var mailchimpSubmitUrl = `//mitsubishielectricmeq.us19.list-manage.com/subscribe/post-json?u=${labels.mailchimpUserId}&id=${labels.mailchimpOptinFormId}&c=res&EMAIL=${this.state.email}&subscribe=Subscribe&c=`;
            e.preventDefault();
            const postBody = {
              message: `
            From : ${this.state.title} ${this.state.firstname} ${this.state.lastname} <br />
            Email : ${this.state.email} <br />
            Message Category : ${this.state.message_category} <br />
            Product Category : ${this.state.product_category} <br />
            Location : ${this.state.location} <br />
            Message : ${this.state.message} <br />
          `,
              // MailChimpUrl: this.state.promo ? mailchimpSubmitUrl : false,
              subject: `${labels.websiteInquirySubjectSuffix} ${
                this.state.subject || this.state.message_category
              }`,
              ToEmailAddress: this.email_to(),
              FromEmailAddress: this.props.defaultFromEmailAddress,
            };
            // console.log('postBody: ', postBody);
            // console.log('this.state: ', this.state);
            this.setState({ contactSubmitting: true });
            if (this.state.promo) {
              $.ajax({
                url: mailchimpSubmitUrl,
                type: "GET",
                data: `EMAIL=${this.state.email}`,
                dataType: "jsonp",
                contentType: "application/json; charset=utf-8",
              });
            }
            axios
              .post("/api/where-to-buy/email", postBody)
              .then(
                (response) => {
                  this.setState({ contactMessage: labels.responseMessage });
                },
                (err) => {
                  this.setState({ contactMessage: labels.responseMessage });
                  throw new Error(err.message);
                }
              )
              .catch((err) => {
                this.setState({ contactMessage: labels.responseMessage });
                throw new Error(err.message);
              });
          },
          (err) => {
            this.reCaptchaRef.current.reset();
            throw new Error(err.message);
          }
        )
        .catch((err) => {
          this.reCaptchaRef.current.reset();
          throw new Error(err.message);
        });
    }
  }

  handleInputChange(target, val) {
    let state = _.cloneDeep(this.state);
    state[target] = val;
    this.setState(state);
  }

  handleSelectChange(target, val) {
    let state = _.cloneDeep(this.state);
    state[target] = val;
    this.setState(state);
  }

  handleClose(e) {
    this.setState({
      openItem: null,
      fixed: false,
      title: "",
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      message: "",
      message_category: "",
      product_category: "",
      location: "",
      subject: "",
      contactSubmitting: false,
      isMobileMenu: false,
      reCaptcha: "",
    });
  }

  componentDidMount() {
    $("body").on("click", "a[href$='#email']", (e) => {
      e.preventDefault();
      $("#modalwrapper").fadeToggle("fast", "linear");
      $("body").toggleClass("modal-open");
    });

    this.setState({ isMobileMenu: window.innerWidth < 1030 });
    window.addEventListener("resize", () => {
      this.setState({ isMobileMenu: window.innerWidth < 1030 });
    });
  }

  handlePromo() {
    const promo = !this.state.promo;
    this.setState({ promo: promo });
  }

  render() {
    // console.log({header : this.props})
    const { logo, location } = this.props;
    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;

    const emailForm = (
      <div id="modalwrapper" className={cx(s.modalwrap)}>
        <div className={cx(s.modalForm)}>
          <div className="container">
            <div className="row">
              <div className="column column-sm-6 column-xs-6">
                <div className={s.logo}>
                  <a className={s.logoLink} href="/">
                    <img
                      width="114"
                      height="44"
                      src={logo.url}
                      alt={logo.alt || logo.label}
                    />
                  </a>
                  {location}
                </div>
              </div>
              <div className="column column-sm-6 column-xs-6">
                <a
                  className={s.closeLink}
                  onClick={this.handleClose.bind(this)}
                  href="#email"
                >
                  {labels.whereToBuyModalCloseLabel} &#10006;
                </a>
              </div>
              <div className="column column-xs-12">
                <SectionTitle
                  headingTagType="h2"
                  title={labels.contactUsFormTitle}
                />
                <p>{labels.contactUsIntro1}</p>
                {this.state.contactSubmitting ? (
                  this.state.contactMessage ? (
                    <div>{this.state.contactMessage}</div>
                  ) : (
                    <div>
                      <p>{labels.contactUsSubmittingMessage}</p>
                    </div>
                  )
                ) : (
                  <div>
                    <p>{labels.contactUsRequiredMessage}</p>
                    {this.state.message_category ===
                      "External Whistle Blower Report" && (
                      <p>{labels.externalWhistleBlowerReportMessage}</p>
                    )}
                    <form onSubmit={this._formPreventDefault}>
                      {this.state.message_category !==
                        "External Whistle Blower Report" && (
                        <>
                          <Select
                            labels={labels}
                            className="select"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.title}
                            name="title"
                            label={labels.title}
                            required={false}
                            options={[
                              { value: "Mr", text: labels.titleMrLabel },
                              { value: "Ms", text: labels.titleMessrsLabel },
                              { value: "Mrs", text: labels.titleMrsLabel },
                              { value: "Miss", text: labels.titleMissLabel },
                              { value: "Dr", text: labels.titleDrLabel },
                            ]}
                          />
                          <Input
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.firstname}
                            name="firstname"
                            type="text"
                            label={labels.fNameLabel}
                            required={true}
                          />
                          <Input
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.lastname}
                            name="lastname"
                            type="text"
                            label={labels.lNameLabel}
                            required={true}
                          />
                          <Input
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.email}
                            name="email"
                            type="email"
                            label={labels.emailLabel}
                            required={true}
                          />
                          <Input
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.subject}
                            name="subject"
                            type="text"
                            label={labels.subjectLabel}
                            required={true}
                          />
                        </>
                      )}
                      <Textarea
                        name="message"
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.message}
                        label={labels.messageCommentsLabel}
                        required={true}
                      />
                      <Select
                        labels={labels}
                        className="select"
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.message_category}
                        name="message_category"
                        label={labels.emailMessageCategoryLabel}
                        required={true}
                        options={[
                          {
                            value: "Accessibility Standards Inquiry",
                            text: labels.accessibilityStandardsInquiry,
                          },
                          { value: "Employment", text: labels.employment },
                          {
                            value: "Product Pre-Sales Inquiry",
                            text: labels.productPreSalesInquiry,
                          },
                          {
                            value: "Product Post Sales Support & Inquiry",
                            text: labels.productPostSalesSupportAndInquiry,
                          },
                          {
                            value: "Website Feedback",
                            text: labels.websiteFeedback,
                          },
                          {
                            value: "External Whistle Blower Report",
                            text: labels.externalWhistleBlowerReport,
                          },
                          { value: "Other", text: labels.other },
                        ]}
                      />
                      {this.state.message_category !==
                        "External Whistle Blower Report" && (
                        <>
                          <Select
                            labels={labels}
                            className="select"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.product_category}
                            name="product_category"
                            label={labels.emailProductCategoryLabel}
                            required={true}
                            options={[
                              {
                                value: "Digital Signage",
                                text: labels.digitalSignage,
                              },
                              {
                                value: "Display Wall",
                                text: labels.displayWalls,
                              },
                              {
                                value: "Energy Recovery and Ventillation",
                                text: labels.energyRecoveryAndVentillation,
                              },
                              { value: "Hand Dryers", text: labels.handDryers },
                              {
                                value: "Heating and Cooling",
                                text: labels.heatingAndCooling,
                              },
                              {
                                value: "Imaging Printers",
                                text: labels.imagingPrinters,
                              },
                              {
                                value: "Photo Printers",
                                text: labels.photoPrinters,
                              },
                              { value: "Projectors", text: labels.projectors },
                              {
                                value: "Solar Energy",
                                text: labels.solarEnergy,
                              },
                              {
                                value: "Stadium and Arena Displays",
                                text: labels.stadiumAndArenaDisplays,
                              },
                              {
                                value: "Other Product (not listed)",
                                text: labels.notListed,
                              },
                              {
                                value: "Not Applicable (not Product related)",
                                text: labels.notApplicable,
                              },
                            ]}
                          />

                          {(this.state.product_category ==
                            "Energy Recovery and Ventillation" ||
                            this.state.product_category ===
                              "Heating and Cooling") && (
                            <div>
                              <input
                                type="checkbox"
                                name="promos"
                                checked={this.state.promo}
                                onChange={(e) =>
                                  this.handlePromo(e.target.value)
                                }
                              />
                              <div>{labels.optInCheckbox}</div>
                            </div>
                          )}
                          <Select
                            labels={labels}
                            className="select"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.location}
                            name="location"
                            label={labels.emailYourLocationLabel}
                            required={true}
                            options={[
                              { value: "AB", text: "Alberta" },
                              { value: "BC", text: "British Columbia" },
                              { value: "MB", text: "Manitoba" },
                              { value: "NB", text: "New Brunswick" },
                              { value: "NL", text: "Newfoundland" },
                              { value: "NT", text: "Northwest Territories" },
                              { value: "NS", text: "Nova Scotia" },
                              { value: "NU", text: "Nunavut" },
                              { value: "ON", text: "Ontario" },
                              { value: "PE", text: "Prince Edward Island" },
                              { value: "PQ", text: "Quebec" },
                              { value: "SK", text: "Saskatchewan" },
                              { value: "YT", text: "Yukon" },
                              { value: false, text: "--------" },
                              {
                                value: "Other Countries/Regions",
                                text: "Other Countries/Regions",
                              },
                              { value: "Africa", text: "Africa" },
                              { value: "Asia", text: "Asia" },
                              { value: "Caribbean", text: "Caribbean" },
                              {
                                value: "CentralAmerica",
                                text: "Central America",
                              },
                              { value: "Europe", text: "Europe" },
                              { value: "Japan", text: "Japan" },
                              { value: "Mediterranean", text: "Mediterranean" },
                              { value: "Mexico", text: "Mexico" },
                              { value: "MiddleEast", text: "Middle East" },
                              { value: "Oceania", text: "Oceania" },
                              { value: "PacificRim", text: "Pacific Rim" },
                              { value: "SouthAmerica", text: "South America" },
                              { value: "USA", text: "United States" },
                              { value: "Other", text: "Other" },
                            ]}
                          />
                          <br />
                        </>
                      )}

                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <ReCAPTCHA
                          ref={this.reCaptchaRef}
                          sitekey={
                            process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V2_SITE_KEY
                          }
                          onChange={(val) => {
                            this.handleInputChange("reCaptcha", val);
                          }}
                        />
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: labels.whereToBuyModalParagraph4,
                        }}
                      />
                      <a
                        className="button"
                        href="#"
                        onClick={this._handleSubmit}
                      >
                        {labels.whereToBuyFormSubmitLabel}
                      </a>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return <div className={cx(s.root)}>{emailForm}</div>;
  }
}

export default _EmailForm;
