import React from "react";
import Slick from "react-slick";

import Cta from "../CallToAction/CallToAction";

import s from "./HeroSlider.module.scss";
import cx from "classnames";

const HeroSlider = (props) => {
  // console.log(props)
  var settings = {
    autoplay: false,
    autoplaySpeed: props.slideDuration,
    speed: props.slideSpeed,
    arrows: props.showArrow == "true",
    dots: props.heroes.length > 1,
    slidesToShow: 1,
    nextArrow: (
      <svg
        role="button"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 10L0.499997 19.5263L0.499998 0.47372L17 10Z"
          fill="white"
        />
      </svg>
    ),
    prevArrow: (
      <svg
        role="button"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-4.80825e-07 10L16.5 0.473722L16.5 19.5263L-4.80825e-07 10Z"
          fill="white"
        />
      </svg>
    ),
  };
  return (
    <div className={s.root}>
      <Slick {...settings}>
        {props.heroes.map((el, index) => {
          let titleSplit = el && el.title ? el.title.split("/newline/") : [""];
          let descSplit =
            el && el.description ? el.description.split("/newline/") : [""];
          return (
            <div
              key={index}
              className={s.slider__item_background}
              style={{
                backgroundImage: `url(${el.image.url})`,
              }}
              role="img"
              aria-label={el.image.label}
            >
              <div className={s.slider__item}>
                <div className="row">
                  <div className="column column-xs-12 column-sm-8">
                    <div className="slider__item_slider__content">
                      <h3 className={s.slider__item__slider_head}>
                        {titleSplit.map((line, index) => {
                          return (
                            <div key={index} className="spanMobileDeskBlock">
                              {line}
                            </div>
                          );
                        })}
                      </h3>
                      <div className={s.slider__item__slider_description}>
                        {descSplit.map((line, index) => {
                          return <div key={index}>{line}</div>;
                        })}
                      </div>
                      {!!el.url ? (
                        <div>
                          <br />
                          <br />
                        </div>
                      ) : (
                        false
                      )}
                      {!!el.url ? (
                        <Cta href={el.url.href} text={el.url.text} />
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slick>
    </div>
  );
};

export default HeroSlider;
