import { useEffect, useState } from "react";
import { handlePreview } from "@agility/nextjs";
import { useRouter } from "next/router";
import Error from "next/error";
import SEO from "./SEO";
import Header from "./HeaderNew/Header";
import Footer from "./FooterNew/Footer";
import MainTemplate from "../agility-pageTemplates/MainTemplate";
import LoadingWidget from "./LoadingWidget";
import GoToTop from "./GoToTop/GoToTop";
import { LabelsProvider } from "src/context/LabelsContext";
import { getSiteMapNestedShowOnMenu } from "src/helpers/utils";
import Head from "next/head";
import HeaderMobile from "./HeaderNew/HeaderMobile";

// set up handle preview
const isPreview = handlePreview();

function Layout(props) {
  const {
    page,
    sitemapNode,
    sitemapNested,
    globalData,
    dynamicPageItem,
    labels,
    notFound,
    redirects,
  } = props;

  const header = globalData?.header;
  const navLinks = getSiteMapNestedShowOnMenu(sitemapNested);

  useEffect(() => {
    var redirectItem = redirects?.items.find((i) => {
      const urlWithNoHttp = i.originUrl
        .replace("https://", "")
        .replace("http://", "");
      return window.location.href.indexOf(urlWithNoHttp) > -1;
    });

    if (!!redirectItem && !!redirectItem?.destinationUrl) {
      window.location = redirectItem.destinationUrl.replace("~", "");
    }
  }, []);
  // console.log({props})

  // useEffect(() => {
  //   const noscript = document.createElement("script");
  //   const iframe = document.createElement("iframe");

  //   noscript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KLPX7VP');`

  //   //iframe.src =
  //   //"https://www.googletagmanager.com/ns.html?id=GTM-GTM-KLPX7VP";
  //   //iframe.src =
  //   // `https://www.googletagmanager.com/ns.html?id=GTM-GTM-KLPX7VP"\nheight="0" width="0" style="display:none;visibility:hidden">`;
  //   // iframe.height = "0";
  //   // iframe.width = "0";
  //   // iframe.style.display = "none";
  //   // iframe.style.visibility = "hidden";

  //   //noscript.appendChild(iframe);

  //   //noscript.async = true;
  //   //document.body.insertBefore(noscript, document.body.firstChild);
  //   document.body.appendChild(noscript);

  //   return () => {
  //     document.body.removeChild(noscript);
  //   };
  // }, []);
  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  const router = useRouter();
  if (router.isFallback) {
    return <LoadingWidget message="Loading Page" />;
  }

  // if page not found, throw 404
  if (notFound === true) {
    return <Error statusCode={404} />;
  }

  if (dynamicPageItem?.seo?.metaDescription) {
    page.seo.metaDescription = dynamicPageItem.seo.metaDescription;
  }

  return (
    <>
      <Head>
        <script
          //strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KLPX7VP');`,
          }}
        />
      </Head>
      <SEO
        title={sitemapNode?.title}
        description={page.seo.metaDescription}
        keywords={page.seo.metaKeywords}
        metaHTML={page.seo.metaHTML}
      />
      <LabelsProvider labels={labels}>
        <a href="#main" class="skip-to-content">
          Skip to main content
        </a>
        <header role="banner" id="mitsubishi-header">
          <Header links={[...navLinks]} {...header} />
          <HeaderMobile links={[...navLinks]} {...header} />
        </header>
        <div className="container">
          <main id="main">
            <MainTemplate {...props} />
            {page?.pageID == 358 && <GoToTop />} {/* show only for VRF page */}
          </main>
        </div>
        <Footer links={[...navLinks]} {...header} />
      </LabelsProvider>
    </>
  );
}

export default Layout;
