import React from "react";
import s from "./HomeOwners_Text.module.scss";
import st from "../SectionTitle/SectionTitle.module.scss";
import cx from "classnames";

const HomeOwners_Text = (props) => {
  const { title, size, size2, align_right, stretch, image, text } = props;
  var order = s.order1;
  var order2 = s.order1;
  if (align_right == "true") {
    order = s.order2;
    order2 = s.order1;
  }

  var column = "column column-xs-4";
  var column2 = "column column-xs-8";
  column = "column column-xs-" + size;
  column2 = "column column-xs-" + size2;

  return (
    <div className={cx(s.root, "homeowners_text")}>
      <h2 className={cx(st.root, 'rx16')}>{title}</h2>
      <div className="row">
        {!!image && (
          <div className={cx(column, order, s.colWrapper)}>
            {stretch == "true" ? (
              <div
                className={s.bgImage}
                style={{ backgroundImage: `url(${image.url})` }}
              />
            ) : (
              <img src={image.url} alt={image.label} />
            )}
          </div>
        )}
        <div
          className={cx(column2, order2, s.colWrapper)}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default HomeOwners_Text;
