import React from 'react';
import LabelsContext from 'src/context/LabelsContext';
import s from './WhereToBuy.module.scss';
import cx from 'classnames';
import axios from 'axios';

import SectionTitle from '../SectionTitle/SectionTitle';
import dropdown from './dropdown.png';
import M from './meq_M.jpg';
import Z from './meq_Z.jpg';
import B from './meq_B.jpg';

import POSTAL from './postalLookup.json';
import DISTRIBUTORS from './distributors.json';
import { flatten } from 'lodash';

const postalRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const icon = {
  M: M,
  Z: Z,
  B: B
}

const ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);

const mapDistrubutors = (postal) => {
  let dists = [];
  for (var index = 0; index < POSTAL.length; index++) {
    const toMatch = POSTAL[index].postal.toUpperCase();
    if (postal.toUpperCase().indexOf(toMatch) == 0) {
      dists.push(POSTAL[index].id)
    }
  }
  return dists;
}

const Input = ({ label, type, required, name, onChange, value }) => {
  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-5">
          <label className={s.label}>{label} {required && <span className={s.required}>*</span>}</label>
        </div>
        <div className="column column-xs-7">
          <input onChange={e => onChange(name, e.target.value)} value={value} className={s.input} type={type || 'text'} name={name} height="150" required={required} />
        </div>
      </div>
    </div>
  )
}

const Textarea = ({ label, type, required, name, onChange }) => {
  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-12">
          <label className={s.label}>{label} {required && <span className={s.required}>*</span>}</label>
        </div>
        <div className="column column-xs-12">
          <textarea onChange={e => onChange(name, e.target.value)} className={s.input} name={name} required={required} />
        </div>
      </div>
    </div>
  )
}


const Select = ({ label, name, options, required, onChange, value, labels }) => {
  const _options = options.map((el, index) => {
    return <option key={index} value={el.value}>{el.text}</option>
  })

  return (
    <div className={cx(s.itemgroup, name)}>
      <div className="row">
        <div className="column column-xs-12 column-sm-5">
          <label className={s.label}>{label} {required && <span className={s.required}>*</span>}</label>
        </div>
        <div className="column column-xs-12 column-sm-7">
          <div className={s.selectwrapper}>
            <select value={value} className={"select"} onChange={e => onChange(name, e.target.value)} required={required} name={name}>
              <option value="">{labels.dropDownSelectDefaultText}</option>
              {_options}
            </select>
            <div className={s.downarrow}>&#9660;</div>
          </div>
        </div>
      </div>
    </div>
  )
}


class WhereToBuy extends React.Component {

  static contextType = LabelsContext;

  constructor(props) {
    super(props);
    this.state = {
      results: null,
      province: "",
      city: "",
      commercial: false,
      postalCode: "",
      productGroup: "Z",
      init: true,
      distributorResults: [],
      distributors: [],
      fetching: false,
      currentDealer: false,
      currentDealerEmail: false
    };
  };


  componentDidMount = () => {
    function getParameterByName(name) {
      const url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    let postalCode = postalRegex.test(getParameterByName('postal_code')) ? getParameterByName('postal_code') : "";
    let commercial = getParameterByName('commercial') == 'residential' ? false : true;
    // let productGroup = (getParameterByName('productGroup') == 'Z' || getParameterByName('productGroup') == 'M') ? getParameterByName('productGroup') : null;
    let productGroup = "M";
    const dists = mapDistrubutors(postalCode);

    if (postalCode) {
      this.setState({
        postalCode: postalCode,
        commercial: commercial,
        productGroup: productGroup,
        distributors: dists
      }, () => {
        console.log('submitting');
        this._handleSubmit({ preventDefault: () => { } });
      })
    }
  }

  _handleSelectionProvince = (event) => {
    this.setState({ province: event.target.value })
  }
  _handleSelectionCity = (event) => {
    this.setState({ city: event.target.value })
  }

  _handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.fetching) {
      console.log('submitting', this.state)
      this.setState({
        fetching: true
      }, () => {
        if (this.state.commercial == false && this.state.productGroup != null) {
          axios.post('/api/where-to-buy', {
            zip: this.state.postalCode,
            productGroup: this.state.productGroup
          })
            .then((response) => {
              let distOverrides = {};
              let results = response.data.sort((a, b) => { a["Score"] - b["Score"] });

              this.props.distributorOverrides.map(o => {
                distOverrides[o.distributorId] = parseInt(o.maxResults);
              });

              if (!!results && results.length > 0 && distOverrides.hasOwnProperty(results[0]['Distributor#'])) {
                const length = distOverrides[results[0]['Distributor#']];
                results = results.slice(0, length);
              }

              this.setState({
                results: results,
                init: true,
                fetching: false
              });
            })
            .catch((error) => {
              this.setState({
                results: [],
                init: true,
                fetching: false,
              });
            })
        } else {
          let distributorResults = [];
          for (var index = 0; index < DISTRIBUTORS.length; index++) {
            if (this.state.distributors.indexOf(DISTRIBUTORS[index].id) > -1) {
              distributorResults.push(DISTRIBUTORS[index]);
            }
            this.setState({
              distributorResults: distributorResults,
              fetching: false
            })
          }
        }
      });
    }
  }

  _resetForm = (event) => {
    this.setState({
      results: null,
      province: "",
      city: "",
      distributors: [],
      postalCode: "",
      init: true,
      fetching: false,
      postalError: false,
      distributorResults: []
    })
  }

  _handleSelectionType = (event) => {
    const commercial = event.target.value === 'commercial' ? true : event.target.value === 'residential' ? false : null;
    // const productGroup = event.target.value === 'residential' ? 'M' : null
    const productGroup = 'M';
    const dists = mapDistrubutors(this.state.postalCode);
    this.setState({
      commercial: commercial,
      productGroup: productGroup,
      results: null,
      distributors: dists,
      distributorResults: []
    });
  }

  _handleDuctType = (event) => {
    const dists = mapDistrubutors(this.state.postalCode);
    this.setState({ productGroup: event.target.value, distributors: dists });
  }

  _handlePostalChange = (event) => {
    const postal = event.target.value;
    const dists = mapDistrubutors(postal);
    const { labels } = this.context;
    if (postalRegex.test(postal) && dists.length > 0) {
      this.setState({
        postalCode: postal,
        postalError: false,
        distributors: dists,
      })
    } else {
      this.setState({
        postalCode: postal,
        postalError: labels.invalidPostalCodeMessage,
        distributors: [],
        distributorResults: [],
        results: null,
      })
    }
  }

  _handleEmailSubmit(e) {
    e.preventDefault();
    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;
    const postBody = {
      subject: this.state.subject,
      message: `
        From : ${this.state.title} ${this.state.firstname} ${this.state.lastname} <br />
        Email : ${this.state.email} <br />
        Phone Number : ${this.state.phonenumber} <br />
        Message : ${this.state.message} <br />
        Dealer : ${this.state.currentDealer} <br />
      `,
      subject: `${labels.websiteInquirySubjectSuffix} ${this.state.subject}`,
      ToEmailAddress: this.state.currentDealerEmail,
      AlternateEmailAddress: this.state.alternateEmailAddress,
      IsDealerLocatorEmail: true
    }
    this.setState({ contactSubmitting: true })
    axios.post('/api/where-to-buy/email', postBody)
      .then((response) => {
        this.setState({ contactMessage: labels.contactSuccessMessage })
        console.log('response: ', response);
      }, (err) => {
        this.setState({ contactMessage: labels.contactSuccessMessage })
        throw new Error(err.message)
      })
      .catch((err) => {
        this.setState({ contactMessage: labels.contactSuccessMessage })
        throw new Error(err.message)
      })
  }

  handleInputChange(target, val) {
    console.log(target, val);
    let state = _.cloneDeep(this.state);
    state[target] = val;
    this.setState(state);
  }

  handleClose(e) {
    this.setState({ currentDealer: false, contactSubmitting: false, currentDealerEmail: false, title: "", message: "", email: "", phonenumber: "", firstname: "", lastname: "", currentDealerEmail: "" });
  }

  setDealerContact(currentDealer, currentDealerEmail, alternateEmailAddress) {
    this.setState({ currentDealer: currentDealer, currentDealerEmail: currentDealerEmail, alternateEmailAddress: alternateEmailAddress });
  }

  render = () => {
    const _style = { backgroundImage: `url(${dropdown.src})` }
    const { labels } = this.context;
    // const labels = typeof window !== 'undefined' ?  mescaLabels : this.props.labels;
    const _icons = typeof window !== 'undefined' ? {
      M: labels.whereToBuyResultsM,
      Z: labels.whereToBuyResultsZ,
      B: labels.whereToBuyResultsB
    } : icon;

    var __cities = [];
    var __provinces = [];
    var _cities = false;
    var _provinces = false;

    if (this.state.results) {
      for (var i = this.state.results.length - 1; i >= 0; i--) {
        __cities.push(this.state.results[i]["City"]);
        __provinces.push(this.state.results[i]["State"]);
      }
      _cities = [...new Set(__cities)];
      _provinces = [...new Set(__provinces)];
    }

    const formClass = !this.state.commercial ? "column column-xs-12 column-sm-6" : "column column-xs-12 column-sm-6";

    const _form = (
      <div className="row">
        <form className={s.formWhere} action="/hvac/where-to-buy">
          <br />
          <div className="row">
            <div className={formClass}><input ref="postalInput" type="text" name="postal_code" onChange={this._handlePostalChange} id="filter_product_zip" className={s.select} placeholder={labels.postalCodePlaceholder} value={this.state.postalCode} autoFocus /></div>

            <div className={formClass}><select onChange={this._handleSelectionType} value={this.state.commercial ? "commercial" : "residential"} name="commercial" id="commercial" className={s.select} style={_style}>
              <option value="residential">{labels.whereToBuy_Residential}</option>
              <option value="commercial">{labels.whereToBuy_Commercial}</option>
            </select></div>

            {/* { !this.state.commercial && <div className={formClass}><select onChange={this._handleDuctType} value={this.state.productGroup} name="productGroup" id="productGroup" className={s.select} style={_style}>
                        <option value="M">{labels.whereToBuy_Ductless}</option>
                        <option value="Z">{labels.whereToBuy_Ducted}</option>
                      </select></div>} */}
          </div>
          {this.state.postalError && <div>{labels.invalidPostalCodeMessage}</div>}

          {this.state.postalError
            ? <input id="wheretobuy-submit" onClick={this._handleSubmit} className="input_submit" type="submit" name="" value={labels.whereToBuyFormSubmitLabel} placeholder="" disabled />
            : <input id="wheretobuy-submit" ref="submit" onClick={this._handleSubmit} className="input_submit" type="submit" name="" value={labels.whereToBuyFormSubmitLabel} placeholder="" />
          }
        </form>
      </div>
    );



    const PostalSpread = flatten(this.props.distributors.map(dist => dist.sortAgainstPinCodes.split(',').map(pincode => {
      let _dist = {
        ...dist,
        postal: pincode
      }
      return _dist;
    })));

    const _distributorResults = PostalSpread.length == 0 ? <div></div> : PostalSpread.map((item, index) => {
      // console.log(this.state.postalCode, item.postal);
      return this.state.postalCode.toUpperCase().indexOf(item.postal.toUpperCase()) === 0 && (<div className={s.filter_listing__result} key={index}>
        <div className={s.filter_listing__result_title}>{item.name}</div>
        <div className={s.filter_listing__result_details}>
          <div dangerouslySetInnerHTML={{ __html: item.content }} /> <br />
          <div dangerouslySetInnerHTML={{ __html: item.contactDetails }} />
          <div>
            <br />
            <a className="button" onClick={e => { e.preventDefault(); this.setDealerContact(item.name, 'where2buy@mitsubishielectric.ca'); }} href="#">{labels.whereToBuyDistributorContactLabel}</a>
          </div>
        </div>
      </div>)
    });


    const __distributorResults = this.state.distributorResults.length == 0 ? <div></div> : this.state.distributorResults.map((item, index) => {
      return (<div className={s.filter_listing__result} key={index}>
        <div className={s.filter_listing__result_title}>{item.title}</div>
        <div className={s.filter_listing__result_details}>
          {item.about} <br />
          <div>
            {item['address'] && (<div className="">
              <br />
              <strong>{labels.whereToBuyResultsLocationLabel}</strong>
              {item['address']}
            </div>)}
            <br />
            <strong>{labels.whereToBuyResultsPhoneLabel}</strong>
            <span dangerouslySetInnerHTML={{ __html: item.phone }} />
            <br />
            <strong>{labels.whereToBuyResultsWebsiteLabel}</strong>
            <a href={item.website}>{item.website}</a><br />
          </div>
          <div>
            <br />
            <a className="button" onClick={e => { e.preventDefault(); this.setDealerContact(item.title, 'where2buy@mitsubishielectric.ca'); }} href="#">{labels.whereToBuyDistributorContactLabel}</a>
          </div>
        </div>
      </div>)
    });

    const _results = !this.state.results ? false : this.state.results.length > 0 ? this.state.results.map((result, index) => {
      return (
        <div className={s.filter_listing__result} key={index}>
          <div className={s.filter_listing__result_title}>{result['Dealer Name']}</div>

          <div className={s.filter_listing__result_details}>
            <div className="row">
              <div className="column column-xs-12 column-sm-6">
                {result['Address'] && (<div className="">
                  <strong>{labels.whereToBuyResultsLocationLabel}</strong>
                  {result['Address'].replace(result['Zip'], '')}
                </div>)}
                {result['Phone number 1'] && (<div className="">
                  <strong>{labels.whereToBuyResultsPhoneLabel}</strong>
                  <a href={`tel:${result['Phone number 1']}`}>{result['Phone number 1']}</a>
                </div>)}
                {result['Url'] && (<div className="">
                  <strong>{labels.whereToBuyResultsWebsiteLabel}</strong>
                  <a href={result['Url']}>{result['Url']}</a>
                </div>)}
                {result['E-mail'] && result['E-mail_distributor'] && (<div>
                  <br />
                  <a className="button" onClick={e => { e.preventDefault(); this.setDealerContact(result['Dealer Name'], result['E-mail'], result['E-mail_distributor']); }} href="#">{labels.whereToBuyDistributorContactLabel}</a>
                </div>)}
              </div>
              <div className="column column-xs-12 column-sm-6" style={{ textAlign: 'right' }}>
                {result['Certification Image ID'] != "0" && <img src={_icons[result['Certification Image ID']]} />}
              </div>
            </div>
          </div>
        </div>
      )
    }) : <div>{labels.searchNoResultsLabel}</div>;

    const emailForm = (
      <div id="modalwrapper" className={cx(s.modalwrap)}>
        <div className={cx(s.modalForm)}>
          <div className="container">
            <div className="row">
              <div className="column column-sm-6 column-xs-6">
              </div>
              <div className="column column-sm-6 column-xs-6">
                <a className={s.closeLink} onClick={this.handleClose.bind(this)}>{labels.whereToBuyModalCloseLabel} &#10006;</a>
              </div>
              <div className="column column-xs-12">
                <SectionTitle headingTagType="h2" title={`Contact ${this.state.currentDealer}`} />
                {this.state.contactSubmitting ? this.state.contactMessage ? <div>{this.state.contactMessage}</div> : (<div>
                  <p>{labels.whereToBuyModalSubmittingLabel}</p></div>) : (<div>
                    <p>{labels.whereToBuyDealerEmailPara1}</p>
                    <p>{labels.whereToBuyDealerEmailPara2}</p>
                    <form onSubmit={this._formPreventDefault}>
                      <Select labels={labels} className="selecttt" onChange={this.handleInputChange.bind(this)} value={this.state.title} name="title" label={labels.titleLabel} required={false} options={[{ value: 'Mr', text: 'Mr.' }, { value: 'Ms', text: 'Ms.' }, { value: 'Mrs', text: 'Mrs.' }, { value: 'Miss', text: 'Miss.' }, { value: 'Dr', text: 'Dr.' }]} />
                      <Input onChange={this.handleInputChange.bind(this)} value={this.state.firstname} name="firstname" type="text" label={labels.fNameLabel} required={true} />
                      <Input onChange={this.handleInputChange.bind(this)} value={this.state.lastname} name="lastname" type="text" label={labels.lNameLabel} required={true} />
                      <Input onChange={this.handleInputChange.bind(this)} value={this.state.email} name="email" type="email" label={labels.emailLabel} required={true} />
                      <Input onChange={this.handleInputChange.bind(this)} value={this.state.phonenumber} name="phonenumber" type="tel" label={labels.phoneNumberLabel} required={true} />
                      <Textarea name="message" label="Message / Comments" onChange={this.handleInputChange.bind(this)} required={true} />
                      <p dangerouslySetInnerHTML={{ __html: labels.whereToBuyEmailDisclaimer1 }} />
                      <br />
                      <p dangerouslySetInnerHTML={{ __html: labels.whereToBuyEmailDisclaimer2 }} />
                      <a className="button" href="#" onClick={this._handleEmailSubmit.bind(this)}>{labels.searchButtonLabel}</a>
                    </form></div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )



    const _init = <p>{this.state.message}</p>;
    return (
      <div className={s.root}>
        {!this.state.init && _init}
        {this.state.init && _form}
        {this.state.fetching && _init}
        {!this.state.fetching && <div className={s.filter_listing__results}>{_results}</div>}
        {this.state.commercial && <div className={s.filter_listing__results}>{_distributorResults}</div>}
        {(this.state.results || this.props.distributors) && <div><p><a style={{ marginTop: '18px' }} className="button" onClick={this._resetForm}>{labels.whereToBuyNewSearchLabel}</a></p></div>}
        {this.state.currentDealer && emailForm}
      </div>
    );
  }
}

export default WhereToBuy;