import React from 'react';

import s from './SectionTitle.module.scss';
import cx from 'classnames';

const SectionTitle = ({title, headingTagType, noMarginTop = 'false'}) => {
  const Tag = headingTagType == '' || !headingTagType ?  'div' : headingTagType;
  return (
      <Tag className={`${cx(s.root, 'rx16')} ${noMarginTop == 'true' ? s.mt0 : ""}`}>{title}</Tag>
  );
};

export default SectionTitle;