import React from "react";
import Script from "next/script";
import ZoneRenderer from "../common/ZoneRenderer";

const MainTemplate = (props) => {
  const { page } = props;

  // HomePage template to render only Body zone
  const zones =
    props?.pageTemplateName == "HomePage"
      ? { Body: page?.zones?.Body }
      : page?.zones;

  return (
    <>
      {!!zones &&
        Object.keys(zones).map((zoneName, idx) => {
          return (
            !!zones[zoneName] &&
            !!zones[zoneName].length &&
            !!zoneName && (
              <ZoneRenderer
                key={idx}
                zone={zones[zoneName]}
                zoneName={zoneName}
              />
            )
          );
        })}
      {!!page?.scripts?.bottom && page.scripts.bottom.indexOf("<") !== 0 && (
        <Script
          id="bottom-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `${page?.scripts?.bottom}`,
          }}
        />
      )}

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-112946599-1"
        strategy="afterInteractive"
      />
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            	window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-112946599-1');
          `,
        }}
      />
    </>
  );
};

export default MainTemplate;
