import React, { useState, useEffect } from 'react';
import s from './TwoColumnsParallelogramTab.module.scss';
import cx from 'classnames';

const ParallelogramTabItems = ({list, theme, activeTab, startIndex = 0, setActiveTab= () => {}}) => {
  return (
    (!!list && !!list.length) && <div className={s.tabsWrapper}> 
      {list.map((item, idx) => {
        let f = item.fields;
        let isActive = item.contentID == activeTab?.contentID;
        return (
          <div 
            key={idx} 
            className={cx(s[`${theme}Tab`], s[`${isActive ? 'activeTab' : ''}`])} 
            onClick={() => setActiveTab({...item, theme: theme, index: startIndex + idx})}
          >
            <p className={s.tabItemTitle}>{f.title}</p>
          </div>
        )
      })}
    </div>
  )
}

export default ParallelogramTabItems;