import React from 'react';

import s from './ContentSwitcher.module.scss';
import cx from 'classnames';

import withViewport from '../withViewport';


class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
      isActive: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._handleClose = this._handleClose.bind(this);

  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  };

  _handleClick = function (index, event) {
    event.preventDefault();
    this.setState({
      selected: index,
      isActive: !this.state.isActive
    });
  };

  _handleClose  = function () {
    this.setState({
      isActive: false
    })
  };

  _renderTitles = function () {
    function labels(child, index) {
      let activeClass = (this.state.selected == index) ? this.props.activeLinkClass : '';
      return (
        <li key={index}>
          <a href="#"
            className={activeClass}
            onClick={(e) => {this._handleClick(index, e)} }>
            {child.props.label}
          </a>
        </li>
      );
    };
    return (
    <div className={this.props.navWrapperClass}>
      <nav className={cx(this.props.navClassName)} >
        <ul>
          {this.props.children.map(labels.bind(this))}
        </ul>
      </nav>
    </div>
    );
  };

  _renderContent = function () {
    let activeClassMobile = (this.state.isActive ? this.props.closeLinkClass : '');
    return (
      <div className={this.props.contentWrapperClass}>
        <div className={cx(s.root, this.props.contentClassName, activeClassMobile)}>

        {this.state.isActive && this.props.viewport.width <= this.props.breakpoint && <a onClick={this._handleClose} className={this.props.modalCloseClass}>×</a>}

        {this.props.children[this.state.selected].props.panelTitle && <h3 className={this.props.contentTitleClass || s.contentTitle}>{this.props.children[this.state.selected].props.panelTitle}</h3>}
        {this.props.children[this.state.selected].props.downloadLink && <div className={s.sideButton}><a className={cx('button')} href={this.props.children[this.state.selected].props.downloadLink.href} target={this.props.children[this.state.selected].props.downloadLink.target}>{this.props.children[this.state.selected].props.downloadLink.text}</a></div>}
        {this.props.children[this.state.selected]}
        </div>
      </div>
    );
  };

  render = function () {
    return (
      <div className={this.props.className}>
        {this._renderTitles()}
        {this._renderContent()}
      </div>
    );
  };

}

class Pane extends React.Component {
  render = function () {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

// Pane.propTypes = {
//   label: React.PropTypes.string.isRequired,
//   children: React.PropTypes.array.isRequired
// }

const ContentSwitcher = {Tabs: withViewport(Tabs), Pane: Pane}
export default ContentSwitcher;