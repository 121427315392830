import React from 'react';
import RichTextArea from '../RichTextArea/RichTextArea';
import s from './TwoGreyColumns.module.scss';
import cx from 'classnames';

const TwoGreyColumns = ({title, description, richText, columnsRatio = '2-2'}) => {
  const ratioArr = columnsRatio?.split('-');
  
  return (
      <div className={cx(s.root, 'two-grey-columns')}>
        <div className={cx(s.column1, `col-span-${ratioArr[0]}`, s[`${!description ? 'smTitle' : ''}`])}>
          <h3 className={s.title}>{title}</h3>
          {!!description && <p className={s.description}>{description}</p>}
        </div>
        <div className={cx(s.column2, `col-span-${ratioArr[1]}`)}>
          {!!richText && <RichTextArea textblob={richText} className="rich-text"/>}
        </div>
      </div>
  );
};

export default TwoGreyColumns;