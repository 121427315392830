import s from "./IconGrid.module.scss";

const IconGrid = ({ enableDarkBackground = "true", iconsList = [] }) => {
  return (
    <div
      className={`${s.root} ${
        !!enableDarkBackground && enableDarkBackground == "true" ? s.bgDark : ""
      }`}
    >
      {iconsList.map((icon, i) => (
        <div key={i}>
          <div className={s.imageWrapper}>
            <img
              src={icon?.fields?.icon?.url}
              alt={icon?.fields?.icon?.label}
              className={s.image}
            />
          </div>
          <h5
            className={s.title}
            dangerouslySetInnerHTML={{ __html: icon.fields.title }}
          ></h5>
          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: icon.fields.description }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default IconGrid;
