import * as React from "react";

function LinkedIn(props) {
  return (
    <svg height="1em" viewBox="0 0 36 26" width="1em" {...props}>
      <image
        fill="none"
        height={26}
        width={36}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAA0CAYAAAApDX79AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAASKADAAQAAAABAAAANAAAAACsE0shAAAEjklEQVRoBe2bT2gcVRjAv29m/8TdKLWoqSWoFw8SatFUu6Vp8VB66sGLQjHX7m6pWnpQLx4CnlpPUqi7i/jnIL2V0kApgqUk2xDBP1QoCAoGCU1tILVJdu1uu/P5fWNmO1125m105mV3M3PYee/73rz3fb95/98OAl9b35kdrtXvnQKi1wjgaZH1yoUAC4B4JZmIv790OjMftN1ow6ndu0ZAW4POXGd+CLiUTMZ3Bg3JkJrT63DkRYgPdisI+K0Y0qwCznPjsgvBF6PX+hw/+mH4YvgVGOkAIkCKWhBT6N1qGUJ/XBO8zPdht7Jfw2pACKsGGO+tFPYWEZGbOY8YRPho/mrOAutjHj4G+xWO+KVsYohGfrU4VnDgyEMS/ldm5CXez5cvIET4qVIY+9oLgOgkjZe+H+S+gLg9fadyspM0qjy6We8LqJsN12WbLyBeCO5WGdJJGlUe3az3BUQEL6Xz5be8HEjnpsYljZe+H+S+gMRBIqswmCvnZWh3HJawLQP41JH16x1T2Sl7btOBgz0xUayW9jdfZAc+KZOoJ4oPspCZ86aYPT9wuYOJojvxZgyvpwYFzwfR4vbwDfduZQ7NA1oNQHiSly+jQHiAN8GGgi90fTkqAanadGsf5pc+lZsucq+f5bWKgPnChPjJ5WLm13Ymj0xcT8zduH0YkE7xoPBUuzQ6ZEpAQRvB67hlAjxcKY5d9Mv7+sRInfVfpY/PXoK/65McfsUvfVg65TAfaMFEdZPgYFUBx11m5ZPMn4ZhHuKmN+eW6wprBTSQTJxcLu1Tru9anV8t7L3FYE+0ynXEtQL6P0cyK6X957l5XtMBxV2G9j7IXfjwiZlH7ty1nhmKDc39dvr5mlvXLoxEF3hWu7OdLiyZ1hrkOPHE2+Xt6dz05FLl/kqjYf1yo7ZQGcxNf+RezjhpH7qb8O1DcQ0R7YC2Z79PVes0wzAOsX/mmo+mRfThY0fLr/v5bJrJP/z0Yei0A1rGux/wEvjZds5YBMfayR1ZwhpcdMK67toBWWQd8HSOyLd/WTwzsur5bEgK7YB4JHrByxdecmzx0m2UXDsg7nse93SWYENH1XZ2aQfUzohulkWAFG8nAhQBUhBQqLuuU1TYu251635VMwPEH6rFfbtsPeIsh/c0dRxIZadneb62exM0sfgoQHzUADwuAPi/BgclbpI53gRClElnZ3Y48fTRqRcFjsT7vgZVS3vsv+ykcuVtvJsJMBD7ucp7TA4M+45QI7COcPhdiZOFvOtJ93nrN7YJapCNwPeH/yF7DoHGn5v4fUDWirxrwLULL8hDESCGwOegl7lyLS3enH/jjlF7k7eE/+IadEkA9X0TEyeVl8GNysLPmNQRgoYwKXENajC6qAY14cUSX3ItyvBx0y6MDXzuyKMmtkaicubVm3wwMMnHUeft8Jp8PWfzDtSuvvudy/0Xw6MapKAWAYoAKQgo1AZ3SguKND2jDsMXXqLglZ4hoDI0BF8M+VJPPkZTld3tevuDOvYlaDsNOQ6WL/V4M/1sGFU0aINb8xObxfYwvjaUsv4BGXJcG/F7UiUAAAAASUVORK5CYII="
      />
    </svg>
  );
}

const MemoLinkedIn = React.memo(LinkedIn);
export default MemoLinkedIn;
