import React from 'react';

import s from './Testimonial.module.scss';
import cx from 'classnames';

const Testimonial = ({quote, cite}) => {
  return (
      <blockquote className={cx(s.root)}>
        <div className={s.quote}>{quote}</div>
        <cite className={s.cite}><strong>{cite.name}</strong><br/>{cite.designation}</cite>
      </blockquote>
  );
};

export default Testimonial;