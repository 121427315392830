import React from 'react';
import AgilityModules from '../agility-pageModules/AgilityModules';
import Row from './RowColumn/Row';
import { findRowColumn } from 'src/helpers/utils';

const ZoneRenderer = ({zone, zoneName}) => {
    let modifiedZone = findRowColumn(zone);
    // console.log({ modifiedZone })

    return <>
        {modifiedZone.map((m, i) => {
            // to not render language swichter richtext temporarily
            if([1368, 1421, 1365, 1474, 4975, 4919, 1371, 1367, 4934, 1370, 1369, 1381, 1569, 1373, 1374, 1377, 1375, 1376, 1378, 4987].find(lswID => m?.item?.contentID == lswID)) return; 
            
            let CurrentComponent = AgilityModules.DefaultModule;
            if (AgilityModules.hasOwnProperty(m.moduleName)) {
                CurrentComponent = AgilityModules[m.moduleName];
            }else if (m.moduleName == 'Row'){
                return <Row key={i} {...m}/>
            }
            if(!m.item) return;
            return <CurrentComponent key={i} contentID={m.item.contentID} {...m.item.fields} {...m.item.properties}/>
        })}
    </>;
}

export default ZoneRenderer;