import React, { useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  RefinementList,
  Hits,
  Pagination,
  useHits,
} from "react-instantsearch";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

function SearchResults({ locale, ...props }) {
  const [initialQuery, setInitialQuery] = React.useState("");

  let text_key = `text_${locale}`;
  let title_key = `title_${locale}`;
  let path_key = `path_${locale}`;

  function getQueryStringValue(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  }
  useEffect(() => {
    setInitialQuery(getQueryStringValue("q"));
  }, [locale]);

  function Hit(props) {
    const {
      hit: { _highlightResult },
    } = props;
    return (
      <a
        href={`${props?.hit?.[path_key]}`}
        style={{
          color: "black",
          textDecoration: "none",
        }}
        onClick={(e) => {
          e.preventDefault();
          console.log(props);
        }}
      >
        <h4
          style={{
            color: ` #f00f00`,
          }}
          dangerouslySetInnerHTML={{
            __html: _highlightResult[title_key]?.value,
          }}
        ></h4>
        <span
          style={{
            fontSize: "0.9em",
          }}
          dangerouslySetInnerHTML={{
            __html: _highlightResult[path_key]?.value,
          }}
        ></span>
        <p
          dangerouslySetInnerHTML={{
            __html: _highlightResult[text_key]?.value,
          }}
        ></p>
      </a>
    );
  }
  return (
    !!initialQuery && (
      <div className="container">
        <InstantSearch
          searchClient={searchClient}
          initialUiState={{
            Website: {
              query: initialQuery,
              page: 1,
              restrictSearchableAttributes: [title_key, text_key, path_key],
            },
          }}
          indexName="Website"
        >
          <SearchBox />
          <Pagination />
          <Hits hitComponent={Hit} />
          <Pagination />
        </InstantSearch>
      </div>
    )
  );
}
export default SearchResults;
