import React, { useState, useEffect } from 'react';
import RichTextArea from '../RichTextArea/RichTextArea';
import ParallelogramTabItems from './ParallelogramTabItems';
import s from './TwoColumnsParallelogramTab.module.scss';
import cx from 'classnames';
import { orderItemsByAgilityOrder } from 'src/helpers/utils';

const TwoColumnsParallelogramTab = (props) => {
  const { column1Title, column1TabList, column2Title, column2TabList } = props;
  const tab1List = orderItemsByAgilityOrder(column1TabList);
  const tab2List = orderItemsByAgilityOrder(column2TabList);
  const totalTabItems = tab1List?.length + tab2List?.length;
  const [ activeTab, setActiveTab ] = useState({...(!!tab1List ? tab1List[0] : {}), theme : 'red', index: 0});
  const [ isDesktop, setIsDesktop ] = useState(false);
  const tabIndex = activeTab?.index + 1;

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [])

  const handleWindowResize = () => {
    setIsDesktop(window.innerWidth > 1030);
  }
  
  return (
      <div className={cx(s.root, `grid-cols-${totalTabItems}`)}>
        <div className={cx(s.column1, `col-span-${isDesktop ? tab1List.length : 1}`)}>
          {!!column1Title && <h3 className={s.title}>{column1Title}</h3>}
          <ParallelogramTabItems 
            list={tab1List}
            theme="red"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className={cx(s.column2, `col-span-${isDesktop ? tab2List.length : 1}`)}>
          {!!column2Title && <h3 className={s.title}>{column2Title}</h3>}
          <ParallelogramTabItems 
            list={tab2List} 
            theme="grey"
            activeTab={activeTab}
            startIndex={tab1List?.length}
            setActiveTab={setActiveTab}
          />
        </div>
        {!!activeTab && <div className={cx(s.tabContentWrapper, s[`${activeTab.theme}TabContent`], `col-span-${isDesktop ? totalTabItems : 1}`)}>
          <div className={s[`${activeTab.theme}Bg`]}/>
          {!!isDesktop && <div 
            className={cx(s[`${activeTab.theme}ParallelogramBg`], s[`${tabIndex < totalTabItems ? 'notLast' : 'last'}`])} 
            style={{
              width: `calc(${tabIndex == totalTabItems ? 1 : tabIndex}/${totalTabItems} * 100% + 5px)`,
            }}
          />}
          <RichTextArea textblob={activeTab?.fields?.content}/>
        </div>}
      </div>
  );
};


export default TwoColumnsParallelogramTab;